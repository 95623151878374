import React, { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import MissionVideoPlayer from "../ReactPlayers/MissionVideoPlayer";
import {
  dd_date_format,
  dd_date_format_UPDATE,
  labels,
  show_proper_words,
} from "src/utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  cointlogo,
  goal_statement_yes_icons_new1,
  MissionCoin,
  saveIcon,
  videoShowLock,
} from "src/assets";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import GeneralQuestionStarRating from "../GeneralComponents/GeneralQuestions/GeneralQuestionStarRating";
import { htmlDecode } from "src/utils/convertHtml";
export default function DayVideoTab({
  played,
  openQuestionsPopup,
  setOpenModal,
  schedule,
  handleChangeAction,
  isSubmitting,
  handleSubmit,
  hide_Top_section,
  handleUnlock,
  missionContentSettings,
  hideSubmitButton,
  playedContent,
  setData,
  onscreen_questions_data,
  isLoadingDataOnScreen,
  setShowQuestionsSreen,
  missionResult,
}) {
  const navigate = useNavigate();
  const { mission__id } = useParams();
  const replacedString = (string) => {
    if (!string) return;
    return string.replace(
      /{completed_date}/g,
      dd_date_format(schedule.completed_at)
    );
  };
  const replacedStringUnlock = (string) => {
    if (!string) return;
    return string.replace(
      /{unlock_date}/g,
      dd_date_format(schedule.started_at)
    );
  };
  const replacedStringNote = (string) => {
    if (!string) return;
    return string
      .replace(/{started_at}/g, dd_date_format(schedule.started_at))
      .replace(/{end_date}/g, dd_date_format(schedule.end_date));
  };
  let find =
    schedule.general_allowed_actions.find(
      (item) => item.is_required === true && !item.is_checked
    ) ||
    schedule.growth_tool_allowed_actions.find(
      (item) => item.is_required === true && !item.is_checked
    );

  const today = moment();
  const startedAt = moment(schedule.started_at);
  const handleChangeOption = (e, childIndex, parentIndex) => {
    const updatedGrowthToolActions = schedule.growth_tool_allowed_actions.map(
      (item, index) => {
        if (index === parentIndex) {
          const updatedStatementArray =
            item.growth_tool_data.statement_array.map((statement, idx) => {
              if (idx === childIndex) {
                return { ...statement, option: e.target.value };
              }
              return statement;
            });
          return {
            ...item,
            growth_tool_data: {
              ...item.growth_tool_data,
              statement_array: updatedStatementArray,
            },
          };
        }
        return item;
      }
    );

    setData({
      ...schedule,
      growth_tool_allowed_actions: updatedGrowthToolActions,
    });
  };

  const handleChangeGeneralOption = (e, index) => {
    const updatedGeneralActions = schedule.general_allowed_actions.map(
      (item, idx) => {
        if (idx === index) {
          return { ...item, statement: e.target.value };
        }
        return item;
      }
    );
    setData({
      ...schedule,
      general_allowed_actions: updatedGeneralActions,
    });
  };
  const handleChangeQuestionAnswer = (e, index) => {
    const updatedQuestionAnswer = schedule.schedule_questions.map(
      (item, idx) => {
        if (idx === index) {
          return { ...item, answer_statement: e.target.value };
        }
        return item;
      }
    );
    setData({
      ...schedule,
      schedule_questions: updatedQuestionAnswer,
    });
  };

  const handleChangeAnswerRadio = (schedule, question_id, selected_option) => {
    if (schedule.schedule_status === "completed") {
      return;
    }
    setData((prevData) => {
      const updatedQuestions = prevData.schedule_questions.map((question) => {
        if (question._id === question_id) {
          return { ...question, answer_statement: selected_option };
        }
        return question;
      });
      return { ...prevData, schedule_questions: updatedQuestions };
    });
  };
  const handleChangeGeneralNote = (e) => {
    setData((prevData) => {
      return { ...prevData, general_note: e.target.value };
    });
  };

  const handleChangeAnswerCheckbox = (
    schedule,
    question_id,
    selected_option
  ) => {
    if (
      schedule.schedule_status === "completed" ||
      schedule.schedule_status === "locked" ||
      schedule.allowed_status === "missed"
    ) {
      return;
    }
    setData((prevData) => {
      const updatedQuestions = prevData.schedule_questions.map((question) => {
        if (question._id === question_id) {
          let newAnswerStatement;
          if (question.answer_statement.includes(selected_option)) {
            newAnswerStatement = question.answer_statement.filter(
              (option) => option !== selected_option
            );
          } else {
            newAnswerStatement = [
              ...question.answer_statement,
              selected_option,
            ];
          }
          return { ...question, answer_statement: newAnswerStatement };
        }
        return question;
      });
      return { ...prevData, schedule_questions: updatedQuestions };
    });
  };

  const handleChangeAnswer = (question_id, selected_value) => {
    setData((prevData) => {
      const updatedQuestions = prevData.schedule_questions.map((question) => {
        if (question._id === question_id) {
          return { ...question, answer_statement: selected_value };
        }
        return question;
      });
      return { ...prevData, schedule_questions: updatedQuestions };
    });
  };

  console.log(schedule.started_at, "data");

  return (
    <div className={`row ${!hide_Top_section ? "mt-3" : ""}`}>
      {!hide_Top_section && (
        <>
          <div
            className={`col-12 ${
              schedule.detailed_description
                ? "col-lg-8"
                : schedule.short_description
                ? "col-lg-8"
                : ""
            } `}
          >
            {schedule.video_url ? (
              <MissionVideoPlayer
                url={schedule.video_url}
                playedContent={playedContent}
                isDisabled={
                  schedule.schedule_status == "locked" ||
                  schedule.allowed_status == "missed"
                }
              />
            ) : (
              <img
                src={s3baseUrl + schedule.image.thumbnail_1}
                className="m-auto"
              />
            )}
          </div>

          <div className={`col-lg-4 mission-levels-overview-tab py-2 `}>
            <h2>
              <span style={{ fontSize: "14pt", color: "#ffd889" }}>
                Schedule Overview
              </span>
            </h2>{" "}
            {schedule.schedule_status == "locked" ||
            schedule.allowed_status == "missed" ? (
              <p>{schedule.short_description}</p>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: schedule.detailed_description,
                }}
              ></div>
            )}
            <div className="mt-3 mb-2 d-flex justify-content-between">
              <span className="indication-text">
                <img
                  className="me-1"
                  src={
                    "https://dd-feature-app-bucket.s3.amazonaws.com/upload/816051a3-2434-4897-a252-af2aead806ce.png"
                  }
                  alt=""
                  style={{ width: "15px" }}
                />{" "}
                {schedule.completed_at
                  ? moment(schedule.completed_at).format("DD MMM YYYY")
                  : schedule.total_number_of_days + " " + "day"}
              </span>
              <span className="indication-text">
                <img
                  className="me-1"
                  src={MissionCoin}
                  alt=""
                  style={{ width: "20px" }}
                />{" "}
                {schedule.reward_coins + " "}
                {schedule.reward_coins ? "Rewarded Coins" : "Achieveable Coins"}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="w-100">
              {schedule?.audio_url && (
                <audio
                  src={s3baseUrl + schedule?.audio_url}
                  controls
                  className={`w-100 ${
                    schedule.schedule_status == "locked" ||
                    schedule.allowed_status == "missed"
                      ? "disabled-audio"
                      : ""
                  }`}
                  onPlay={schedule.video_url ? undefined : playedContent}
                  controlsList="nodownload"
                />
              )}
            </div>
            {schedule.schedule_status == "completed" &&
              schedule.question_configration?.show_question &&
              schedule.question_configration?.button_text && (
                <>
                  {schedule.selected_questions.filter(
                    (question) => question.show_question !== "on_screen"
                  ).length > 0 && (
                    <button
                      className="mc-button-contained ms-4"
                      disabled={Boolean(schedule.schedule_status === "locked")}
                      onClick={() => {
                        setShowQuestionsSreen(1);
                        localStorage.setItem("showQuestionsSreen", 1);
                      }}
                    >
                      {schedule.question_configration?.button_text}
                    </button>
                  )}
                </>
              )}
          </div>
          {schedule.schedule_status == "locked" && (
            <>
              <p
                className="start-mission-note"
                style={{ marginTop: "10px", position: "relative" }}
              >
                <span className="note-label">Note :</span>{" "}
                {htmlDecode(
                  replacedStringUnlock(
                    missionResult.mission.content_settings.unlock_date_statement
                  )
                )}
              </p>
            </>
          )}
        </>
      )}
      {schedule.schedule_status !== "locked" &&
        schedule.allowed_status !== "missed" && (
          <>
            {schedule.general_allowed_actions?.length > 0 && (
              <div className="col-12 position-relative">
                {schedule.schedule_status === "locked" && (
                  <>
                    {schedule.allowed_status !== "missed" && (
                      <>
                        <Tooltip
                          title={`${
                            schedule.mission_status === "not_started"
                              ? "Start mission before unlock schedule"
                              : startedAt.isAfter(today, "day")
                              ? `You can't unlock it before ${dd_date_format_UPDATE(
                                  schedule.started_at
                                )}`
                              : "Click to unlock"
                          }`}
                        >
                          <button
                            style={{ zIndex: "9" }}
                            variant="contained"
                            className={
                              schedule.schedule_status === "locked"
                                ? `small-contained-button mui-card-button d-flex centerAlign_new`
                                : "small-contained-button mui-card-button mui-card-button-program d-flex centerAlign_new"
                            }
                            onClick={() => handleUnlock(schedule)}
                          >
                            <img
                              src={videoShowLock}
                              alt=""
                              className="thumbnail-icon me-2 unlockIconNew "
                            />
                            <span>click here to unclock</span>
                          </button>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
                <form
                  className={` ${
                    hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
                  } allowed-actions-box pb-4`}
                >
                  {!hide_Top_section && (
                    <h2
                      style={{ marginBottom: "14px", fontSize: "18px" }}
                      className=""
                    >
                      {schedule.content_settings?.action_statement_heading}
                    </h2>
                  )}

                  {schedule.general_allowed_actions.map((item, index) => {
                    let { _id, action_statement, is_required, is_checked } =
                      item;

                    let find_answer = null;
                    if (schedule.completed_allowed_actions?.length > 0) {
                      find_answer = schedule.completed_allowed_actions.find(
                        (i) => i._id == _id
                      );
                    }

                    let isDisabled = Boolean(
                      schedule.schedule_status === "locked"
                    );

                    return (
                      <>
                        <Tooltip
                          title={
                            schedule.allowed_status == "missed" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replacedStringNote(
                                    missionContentSettings?.missed_schedule_description
                                  ),
                                }}
                                className="schedule_completed_description"
                              ></div>
                            ) : (
                              ""
                            )
                          }
                        >
                          <div className="action-main-container mb-2">
                            <div
                              className={`mt-2 action-checkbox-box  ${
                                schedule.allowed_status == "missed"
                                  ? " "
                                  : isDisabled
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() =>
                                schedule.allowed_status == "missed" ||
                                schedule.schedule_status === "locked" ||
                                schedule.schedule_status === "completed"
                                  ? ""
                                  : handleChangeAction(
                                      _id,
                                      isDisabled,
                                      schedule,
                                      item
                                    )
                              }
                            >
                              <div
                                className={`action-checkbox ${
                                  is_checked ? "checked" : ""
                                }`}
                              >
                                {is_checked && (
                                  <i class="fa-solid fa-check"></i>
                                )}
                              </div>
                              <div className="checkbox-label">
                                {action_statement}
                              </div>
                              <span className="required-asterisk ms-1">
                                {is_required && "*"}
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                      </>
                    );
                  })}
                </form>
              </div>
            )}
            {schedule?.schedule_questions?.length > 0 && (
              <div className="col-12 position-relative">
                {schedule.schedule_status === "locked" && (
                  <>
                    {schedule.allowed_status !== "missed" && (
                      <>
                        <Tooltip
                          title={`${
                            schedule.mission_status === "not_started"
                              ? "Start mission before unlock schedule"
                              : startedAt.isAfter(today, "day")
                              ? `You can't unlock it before ${dd_date_format_UPDATE(
                                  schedule.started_at
                                )}`
                              : "Click to unlock"
                          }`}
                        >
                          <button
                            style={{ zIndex: "9" }}
                            variant="contained"
                            className={
                              schedule.schedule_status === "locked"
                                ? `small-contained-button mui-card-button d-flex centerAlign_new`
                                : "small-contained-button mui-card-button mui-card-button-program d-flex centerAlign_new"
                            }
                            onClick={() => handleUnlock(schedule)}
                          >
                            <img
                              src={videoShowLock}
                              alt=""
                              className="thumbnail-icon me-2 unlockIconNew "
                            />
                            <span>click here to unclock</span>
                          </button>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
                <form
                  className={` ${
                    hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
                  } allowed-actions-box pb-4`}
                >
                  {!hide_Top_section && (
                    <h2 style={{ marginBottom: "14px", fontSize: "18px" }}>
                      {schedule?.content_settings?.onscreen_question_title ||
                        "Content Questions"}
                    </h2>
                  )}

                  {schedule.schedule_questions.map((item, index) => {
                    let { _id, is_required } = item;

                    let isDisabled = Boolean(
                      schedule.schedule_status === "locked"
                    );
                    let find_answer = schedule.completed_allowed_actions.find(
                      (answer) => answer._id == item._id
                    );
                    return (
                      <>
                        <Tooltip
                          title={
                            schedule.allowed_status == "missed" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replacedStringNote(
                                    missionContentSettings?.missed_schedule_description
                                  ),
                                }}
                                className="schedule_completed_description"
                              ></div>
                            ) : (
                              ""
                            )
                          }
                        >
                          <div className="action-main-container mb-2">
                            <div
                              className={`mt-2 action-checkbox-box  ${
                                schedule.allowed_status == "missed"
                                  ? " "
                                  : isDisabled
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div className="checkbox-label">
                                <div
                                  className="question-container"
                                  dangerouslySetInnerHTML={{
                                    __html: item.question_statement,
                                  }}
                                ></div>
                              </div>
                              <span className="required-asterisk ms-1">
                                {is_required && "*"}
                              </span>
                            </div>
                            {item.question_type == "checkbox" ? (
                              <FormControl>
                                <FormGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  name="radio-buttons-group"
                                  value={item?.answer}
                                  disabled={Boolean(
                                    schedule.schedule_status === "completed" ||
                                      schedule.schedule_status === "locked" ||
                                      schedule.allowed_status === "missed"
                                  )}
                                  onChange={(e) =>
                                    handleChangeAnswerCheckbox(
                                      schedule,
                                      item._id,
                                      e.target.value
                                    )
                                  }
                                >
                                  {item?.options.map((option, index) => {
                                    let answer_statement =
                                      item?.answer_statement;
                                    let checkbox_answer = null;
                                    if (answer_statement) {
                                      checkbox_answer = answer_statement?.find(
                                        (ans) => ans === option
                                      );
                                    }

                                    return (
                                      <div
                                        className={`question-answer`}
                                        key={option.id}
                                      >
                                        <FormControlLabel
                                          value={option}
                                          id={`capsule${index}`}
                                          className={`answer ${
                                            Boolean(
                                              schedule.schedule_status ===
                                                "completed" ||
                                                schedule.schedule_status ===
                                                  "locked" ||
                                                schedule.allowed_status ===
                                                  "missed"
                                            )
                                              ? "disabled-mission"
                                              : ""
                                          }`}
                                          control={
                                            <Checkbox
                                              checked={Boolean(checkbox_answer)}
                                            />
                                          }
                                          label={option}
                                        />
                                      </div>
                                    );
                                  })}
                                </FormGroup>
                              </FormControl>
                            ) : item.question_type == "mcq" ? (
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  name="radio-buttons-group"
                                  value={item?.answer_statement}
                                  disabled={Boolean(
                                    schedule.schedule_status === "completed" ||
                                      schedule.schedule_status === "locked" ||
                                      schedule.allowed_status === "missed"
                                  )}
                                  onChange={(e) =>
                                    handleChangeAnswerRadio(
                                      schedule,
                                      item._id,
                                      e.target.value
                                    )
                                  }
                                >
                                  {item?.options.map((option, index) => (
                                    <div
                                      className={`question-answer  ${
                                        find_answer?.answer_statement
                                          ? option === item?.answer_statement
                                            ? "selectedAnswer"
                                            : ""
                                          : ""
                                      }`}
                                      key={option.id}
                                    >
                                      <FormControlLabel
                                        value={option}
                                        id={`capsule${index}`}
                                        className={`answer ${
                                          Boolean(
                                            schedule.schedule_status ===
                                              "completed" ||
                                              schedule.schedule_status ===
                                                "locked" ||
                                              schedule.allowed_status ===
                                                "missed"
                                          )
                                            ? "disabled-mission"
                                            : ""
                                        }`}
                                        control={<Radio />}
                                        label={option}
                                      />
                                    </div>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            ) : item.question_type == "scaling" ? (
                              <FormControl>
                                <ul className="list-unstyled pt-1 mb-0">
                                  <li className="row">
                                    <div
                                      className={`col-12 ${
                                        Boolean(
                                          schedule.schedule_status ===
                                            "completed" ||
                                            schedule.schedule_status ===
                                              "locked" ||
                                            schedule.allowed_status === "missed"
                                        )
                                          ? "disabled-mission"
                                          : ""
                                      }`}
                                    >
                                      <GeneralQuestionStarRating
                                        question={item}
                                        handleAnswer={handleChangeAnswer}
                                        find_answer={item}
                                        isDisabled={
                                          schedule.schedule_status ===
                                            "completed" ||
                                          schedule.schedule_status ===
                                            "locked" ||
                                          schedule.allowed_status === "missed"
                                        }
                                        disableTooltip={true}
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </FormControl>
                            ) : (
                              <div className="action-checkbox-box-description">
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  className={`text-color textarea-autosize  ${
                                    Boolean(
                                      schedule.schedule_status ===
                                        "completed" ||
                                        schedule.schedule_status === "locked" ||
                                        schedule.allowed_status === "missed"
                                    )
                                      ? "disabled-mission"
                                      : ""
                                  }`}
                                  value={item.answer_statement}
                                  name="answer_statement"
                                  placeholder={item?.question_placeholder}
                                  sx={{ color: "white" }}
                                  disabled={Boolean(
                                    schedule.schedule_status === "completed" ||
                                      schedule.schedule_status === "locked" ||
                                      schedule.allowed_status === "missed"
                                  )}
                                  minRows={3}
                                  onChange={(e) =>
                                    handleChangeQuestionAnswer(e, index)
                                  }
                                  style={{
                                    width: "100%",
                                    borderRadius: "11px",
                                    background: "#1d1c1d",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "15px",
                                    color: "white",
                                    fontSize: "15px",
                                    border: "2px solid rgba(71, 66, 34, 0.3)",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </>
                    );
                  })}
                </form>
              </div>
            )}

            {schedule.growth_tool_allowed_actions?.length > 0 && (
              <div className="row mt-2">
                {schedule.growth_tool_allowed_actions.map(
                  (item, parentIndex) => {
                    let {
                      _id,
                      action_statement,
                      second_action_statement,
                      is_required,
                      is_checked,
                    } = item;

                    let find_answer = null;
                    if (schedule.completed_allowed_actions?.length > 0) {
                      find_answer = schedule.completed_allowed_actions.find(
                        (i) => i._id == _id
                      );
                    }

                    let isDisabled = Boolean(
                      schedule.schedule_status === "locked" ||
                        schedule.schedule_status === "completed" ||
                        schedule.allowed_status === "missed"
                    );

                    return (
                      <div
                        className={`position-relative  ${
                          schedule.growth_tool_allowed_actions?.length <= 1
                            ? "col-lg-12"
                            : "col-lg-6"
                        } pe-0`}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {schedule.schedule_status === "locked" && (
                          <>
                            {schedule.allowed_status !== "missed" && (
                              <>
                                <Tooltip
                                  title={`${
                                    schedule.mission_status === "not_started"
                                      ? "Start mission before unlock schedule"
                                      : startedAt.isAfter(today, "day")
                                      ? `You can't unlock it before ${dd_date_format_UPDATE(
                                          schedule.started_at
                                        )}`
                                      : "Click to unlock"
                                  }`}
                                >
                                  <button
                                    style={{ zIndex: "9" }}
                                    variant="contained"
                                    className={
                                      schedule.schedule_status === "locked"
                                        ? `small-contained-button mui-card-button d-flex centerAlign_new`
                                        : "small-contained-button mui-card-button mui-card-button-program d-flex centerAlign_new"
                                    }
                                    onClick={() => handleUnlock(schedule)}
                                  >
                                    <img
                                      src={videoShowLock}
                                      alt=""
                                      className="thumbnail-icon me-2 unlockIconNew "
                                    />
                                    <span>click here to unclock</span>
                                  </button>
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}

                        <form
                          className={` ${
                            hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
                          } allowed-actions-box pb-4 flex-grow-1`}
                        >
                          {!hide_Top_section && (
                            <h2
                              style={{ marginBottom: "14px", fontSize: "20px" }}
                              className=""
                            >
                              {item.tool == "gratitude_daily"
                                ? schedule?.content_settings
                                    ?.gratitude_action_title ||
                                  show_proper_words(item.tool)
                                : schedule?.content_settings
                                    ?.dynamite_dairy_action_title ||
                                  show_proper_words(item.tool)}
                            </h2>
                          )}

                          <>
                            <Tooltip
                              title={
                                schedule.allowed_status == "missed" ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: replacedStringNote(
                                        missionContentSettings?.missed_schedule_description
                                      ),
                                    }}
                                    className="schedule_completed_description"
                                  ></div>
                                ) : (
                                  ""
                                )
                              }
                            >
                              <div className="action-main-container mb-2">
                                {/* <div
                              className={`mt-2 action-checkbox-box-new  ${
                                schedule.allowed_status == "missed" ? " " : ""
                              }`}
                            >
                              <div className="checkbox-label">
                                {item.tool == "gratitude_daily"
                                  ? second_action_statement
                                  : action_statement}

                                <span className="required-asterisk ms-1">
                                  {is_required && "*"}
                                </span>
                              </div>
                            </div> */}
                                {item.growth_tool_data.statement_array.map(
                                  (items, index) => {
                                    const lable =
                                      item.tool === "gratitude_daily"
                                        ? labels.gratitudeLabels[index]
                                        : labels.dailyDynamiteLabels[index];

                                    return (
                                      <>
                                        <div className="action-checkbox-box-description">
                                          <span className="ms-2 custom-lable">
                                            {lable}
                                          </span>
                                          <span className="required-asterisk ms-1">
                                            {is_required && "*"}
                                          </span>
                                          <TextareaAutosize
                                            aria-label="empty textarea"
                                            className={`text-color textarea-autosize ${
                                              isDisabled
                                                ? "disabled-mission"
                                                : ""
                                            }`}
                                            placeholder={index + 1}
                                            value={items.option}
                                            sx={{ color: "white" }}
                                            disabled={isDisabled}
                                            minRows={1}
                                            onChange={(e) => {
                                              handleChangeOption(
                                                e,
                                                index,
                                                parentIndex
                                              );
                                            }}
                                            style={{
                                              width: "100%",
                                              borderRadius: "11px",
                                              background: "#1d1c1d",
                                              paddingTop: "10px",
                                              paddingBottom: "10px",
                                              paddingLeft: "15px",
                                              color: "white",
                                              fontSize: "15px",
                                              border:
                                                "2px solid rgba(71, 66, 34, 0.3)",
                                            }}
                                          />
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </Tooltip>
                          </>
                        </form>
                      </div>
                    );
                  }
                )}
              </div>
            )}

            <>
              {schedule?.content_settings?.is_show_general_note && (
                <div className="action-checkbox-box-description  mt-3">
                  <h4 className="ms-2" style={{ color: "#ffd889" }}>
                    {schedule?.content_settings?.general_note_title ||
                      "Journal"}
                  </h4>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    className={`text-color textarea-autosize  ${
                      Boolean(
                        schedule.schedule_status === "completed" ||
                          schedule.schedule_status === "locked" ||
                          schedule.allowed_status === "missed"
                      )
                        ? "disabled-mission"
                        : ""
                    }`}
                    name="statement"
                    value={schedule?.general_note}
                    onChange={(e) => handleChangeGeneralNote(e, schedule._id)}
                    disabled={Boolean(
                      schedule.schedule_status === "locked" ||
                        schedule.schedule_status === "completed" ||
                        schedule.allowed_status === "missed"
                    )}
                    placeholder={labels.journal}
                    sx={{ color: "white" }}
                    minRows={4}
                    style={{
                      width: "100%",
                      borderRadius: "11px",
                      background: "#1d1c1d",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "15px",
                      color: "white",
                      fontSize: "15px",
                      border: "2px solid rgba(71, 66, 34, 0.3)",
                    }}
                  />
                </div>
              )}
            </>
          </>
        )}

      <div className="text-end">
        {schedule.schedule_status !== "completed" &&
          schedule.allowed_status !== "missed" && (
            <>
              {schedule.schedule_status !== "locked" && (
                <>
                  {!hideSubmitButton && (
                    <button
                      className="mc-button-contained mb-3 mt-3"
                      disabled={
                        Boolean(schedule.schedule_status === "locked") ||
                        isSubmitting
                      }
                      onClick={(e) => openQuestionsPopup(e, schedule)}
                      // onClick={(e) => handleSubmit(e, schedule)}
                    >
                      {isSubmitting
                        ? "Submitting..."
                        : schedule.content_settings
                            ?.complete_schedule_button_text ||
                          "Complete Schedule"}
                    </button>
                  )}
                </>
              )}
            </>
          )}
      </div>

      {!hide_Top_section && (
        <>
          <div className="col-12">
            {schedule.content_settings?.schedule_completed_description &&
              schedule.schedule_status == "completed" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: replacedString(
                      schedule.content_settings?.schedule_completed_description
                    ),
                  }}
                  className="mt-4 schedule_completed_description"
                ></div>
              )}
          </div>
          {schedule.allowed_status === "missed" && (
            <div className="col-12">
              {missionContentSettings?.missed_schedule_description &&
                schedule.allowed_status == "missed" && (
                  <p
                    className="start-mission-note-new mt-3"
                    id="note_66a722347421ac375573836f"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replacedStringNote(
                          missionContentSettings?.missed_schedule_description
                        ),
                      }}
                      className="schedule_completed_description"
                    ></div>
                  </p>
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
