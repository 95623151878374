import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { videoShowLock } from "src/assets";
import { dd_date_format } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

export default function QuestLevelsScheduleTab(props) {
  const { handleClick, schedules, missionDetail, missionSchedules } = props;
  const { state } = useLocation();

  const ref = useRef(null);

  // useEffect(() => {
  //   const adjustHeight = () => {
  //     const videoDetailImageBox = document.getElementById(
  //       "video_detail_image_box"
  //     );
  //     const scheduleHeading = document.getElementById("schedule-heading-box");

  //     if (videoDetailImageBox && screen.width > 767) {
  //       const height = videoDetailImageBox.offsetHeight;
  //       const element = ref.current;
  //       if (height > 0 && element) {
  //         element.style.height = `${height - scheduleHeading.offsetHeight}px`;
  //         element.style.overflow = "auto";
  //       }
  //     }

  //     if (state?.schedule_id) {
  //       const element = document.getElementById(state?.schedule_id);
  //       if (element) {
  //         const container = element.closest(".mission-levels-schedule-tab");
  //         const elementPosition = element.offsetTop;
  //         const containerPosition = container.offsetTop;
  //         container.scrollTo({
  //           top: elementPosition - containerPosition,
  //           behavior: "smooth",
  //         });
  //       }
  //     }
  //   };

  //   const timeoutId = setTimeout(adjustHeight, 300);
  //   return () => clearTimeout(timeoutId);
  // }, [ref, schedules, missionDetail]);

  const replacedString = (string, unlock_date) => {
    if (!string) return;
    return string.replace(/{unlock_date}/g, dd_date_format(unlock_date));
  };

  const replacedStringNote = (string, schedule) => {
    if (!string) return;
    return string
      .replace(/{started_at}/g, dd_date_format(schedule.started_at))
      .replace(/{end_date}/g, dd_date_format(schedule.end_date));
  };

  console.log(missionSchedules?._id, "missionSchedulesmissionSchedules");
  return (
    <div className="row mission-levels-schedule-tab" ref={ref}>
      <div className={`${screen.width > 767 ? "card" : ""}`}>
        <div className="col-12">
          {schedules.map((schedule) => {
            const today = moment();
            const startedAt = moment(schedule.started_at);
            let tooltip_title = replacedStringNote(
              missionDetail?.content_settings?.missed_schedule_description,
              schedule
            );
            return (
              <div
                className="position-relative"
                key={schedule._id}
                id={schedule._id}
              >
                <Tooltip
                  title={
                    schedule.allowed_status === "missed" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tooltip_title,
                        }}
                        className="schedule_completed_description"
                      />
                    ) : missionDetail.mission_status === "not_started" ? (
                      "Please start Quest first !"
                    ) : schedule.schedule_status === "locked" ? (
                      missionDetail.mission_status === "not_started" ? (
                        "Start Quest before unlock schedule"
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  }
                >
                  <div
                    className={`schedule-click mb-b ${
                      schedule._id === missionSchedules?._id
                        ? "in-progress-schedule"
                        : ""
                    }`}
                    key={schedule.title}
                    onClick={() => handleClick(schedule)}
                  >
                    <div className="d-flex justify-content-between">
                      <h6 style={{ fontSize: "16px" }}>
                        {schedule.main_heading}
                      </h6>
                      {schedule.schedule_status === "locked" && (
                        <Tooltip
                          title={`${
                            missionDetail.mission_status === "not_started"
                              ? "Start Quest before unlock day"
                              : startedAt.isAfter(today, "day")
                              ? `You can't unlock it before ${dd_date_format(
                                  schedule.started_at
                                )}`
                              : "Click to unlock"
                          }`}
                        >
                          <img
                            src={videoShowLock}
                            alt=""
                            className="lock-thumbnail-icon"
                          />
                        </Tooltip>
                      )}
                    </div>
                    <p className="mb-0 " fontSize={{ fontSize: "14px" }}>
                      {schedule.short_description}
                    </p>
                    {missionDetail.content_settings?.unlock_date_statement &&
                      missionDetail.mission_status === "in_progress" &&
                      schedule.schedule_status == "locked" && (
                        <p
                          className="unlock-date-note"
                          style={{ fontSize: "12px" }}
                        >
                          {htmlDecode(
                            replacedString(
                              missionDetail.content_settings
                                ?.unlock_date_statement,
                              schedule.started_at
                            )
                          )}
                        </p>
                      )}
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
