import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  appLogoIcon,
  goal_statement_no_new_icons,
  goal_statement_yes_icons_new1,
} from "src/assets";

import {
  add_answers_api,
  add_specific_answers_api,
  questions_listing_api,
  questions_listing_api_for_mission,
} from "src/DAL/Questions/Questions";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { makeStyles } from "@mui/styles";
import GeneralQuestionStarRating from "src/components/GeneralComponents/GeneralQuestions/GeneralQuestionStarRating";
import { s3baseUrl } from "src/config/config";
import jsPDF from "jspdf";
import { upload_document_api } from "src/DAL/GeneralAPIs/GeneralAPIs";
import { delete_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import ReactDOMServer from "react-dom/server";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import KimResponce from "src/components/message/KimResponce";
import { show_proper_words, TIME_ZONE_CONVERSION } from "src/utils/constants";
import { dd_date_format } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function GeneralQuestionsList({
  created_for,
  remove_back_button,
  check_user,
  className,
  collapseAll,
  hide_dynamite_reply,
  onSubmitSuccess,
  created_for_id,
  mission_schedule_id,
  prevent_single_answer,
  question_answers,
  questions_list,
  isDisabled,
  hideSubmitButton,
  disableTooltip,
  for_mission,
  for_unlock_mission,
  historyTitle,
  created_title,
  is_inline,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  if (!created_for_id) {
    created_for_id = params.created_for_id;
  }
  const { userInfo, adminTimeZone } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isDonwloadingPdf, setIsDonwloadingPdf] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [moduleTitle, setModuleTitle] = useState("");
  const [questionConfigration, setQuestionConfigration] = useState([]);
  const [questionAnswers, setQuestionsAnswers] = useState([]);
  const [responceDrawer, setResponceDrawer] = useState(false);
  const [comments, setComments] = useState([]);
  const [question, setQuestion] = useState("");
  const [repliesDrawer, setRepliesDrawer] = useState(false);
  const [replies, setReplies] = useState([]);
  const [selfImageCompletedStatus, setSelfImageCompletedStatus] = useState("");
  const [selfImageCompletedDate, setSelfImageCompletedDate] = useState();
  const [lastSubmitDate, setLastSubmitDate] = useState({});

  const get_questions_list = async () => {
    let postObject = {
      created_for_id: created_for_id || "",
      created_for: created_for,
      check_user: check_user ? true : false,
    };
    if (for_mission) {
      postObject.other_module_id = mission_schedule_id;
      postObject.created_for_id = params.created_for_id ?? params.mission__id;
    }
    const result = await (for_mission
      ? questions_listing_api_for_mission(postObject)
      : questions_listing_api(postObject));
    if (result.code === 200) {
      let data = result.questionnaire;
      if (for_mission || for_unlock_mission) {
        data = result.questionnaire.map((question, index) => {
          return {
            ...question,
            isExpanded: index == 0 ? true : false,
            isLoading: false,
          };
        });
      }
      if (!collapseAll) {
        data = result.questionnaire.map((question) => {
          return {
            ...question,
            isExpanded: true,
            isLoading: false,
          };
        });
      }

      setQuestions(data);
      setQuestionsAnswers(result.questionnaire_replies);
      setQuestionConfigration(result.question_configration);
      setModuleTitle(result.title);
      setReplies(result.dynamite_reply);
      setSelfImageCompletedStatus(result.self_image_status);
      setSelfImageCompletedDate(result.self_image_completed_date);
      setLastSubmitDate(result.last_submit_date);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeAnswer = (question_id, selected_option) => {
    if (questionAnswers.length > 0) {
      let answered = questionAnswers.find(
        (answer) => answer._id == question_id
      );
      if (answered) {
        setQuestionsAnswers((old_answers) => {
          if (!selected_option) {
            return old_answers.filter((ans) => ans._id !== question_id);
          }
          return old_answers.map((answers) => {
            if (answers._id == question_id) {
              return {
                ...answers,
                answer_statement: selected_option,
              };
            } else {
              return answers;
            }
          });
        });
      } else {
        setQuestionsAnswers((old_answers) => [
          ...old_answers,
          {
            _id: question_id,
            answer_statement: selected_option,
          },
        ]);
      }
    } else {
      setQuestionsAnswers([
        {
          _id: question_id,
          answer_statement: selected_option,
        },
      ]);
    }
  };

  const fileChangedHandler = async (question_id, e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imageUpload = await upload_document_api(formData);
    if (imageUpload.code == 200) {
      let document_url = imageUpload.image_path;
      if (questionAnswers.length > 0) {
        let answered = questionAnswers.find(
          (answer) => answer._id == question_id
        );
        if (answered) {
          setQuestionsAnswers((old_answers) =>
            old_answers.map((answers) => {
              if (answers._id == question_id) {
                return {
                  ...answers,
                  document_url: document_url,
                };
              } else {
                return answers;
              }
            })
          );
        } else {
          setQuestionsAnswers((old_answers) => [
            ...old_answers,
            {
              _id: question_id,
              document_url: document_url,
            },
          ]);
        }
      } else {
        setQuestionsAnswers([
          {
            _id: question_id,
            document_url: document_url,
          },
        ]);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeAnswerCheckbox = (question_id, selected_option) => {
    if (questionAnswers.length > 0) {
      let answered = questionAnswers.find(
        (answer) => answer._id == question_id
      );
      if (answered) {
        setQuestionsAnswers((old_answers) =>
          old_answers.map((answers) => {
            if (answers._id == question_id) {
              let find_already_exist = answers?.answer_statement?.find(
                (prev) => prev == selected_option
              );
              let new_array = answers.answer_statement;
              if (find_already_exist) {
                new_array = answers.answer_statement.filter(
                  (prev) => prev !== selected_option
                );
              } else {
                if (new_array && new_array.length > 0) {
                  new_array = [...answers.answer_statement, selected_option];
                } else {
                  new_array = [selected_option];
                }
              }
              return {
                ...answers,
                answer_statement: new_array,
              };
            } else {
              return answers;
            }
          })
        );
      } else {
        setQuestionsAnswers((old_answers) => [
          ...old_answers,
          {
            _id: question_id,
            answer_statement: [selected_option],
          },
        ]);
      }
    } else {
      setQuestionsAnswers([
        {
          _id: question_id,
          answer_statement: [selected_option],
        },
      ]);
    }
  };

  const handleKimResponce = (question, statement) => {
    setResponceDrawer(true);
    setComments(question.comments);
    setQuestion(statement);
  };

  const handleOpenDrawer = () => {
    setResponceDrawer(true);
  };

  const handleCloseDrawer = () => {
    setResponceDrawer(false);
  };

  const expandArea = (index, value) => {
    handle_update_questions(index, "isExpanded", value);
  };

  const handle_update_questions = (index, name, value) => {
    setQuestions((old) =>
      old.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }
        return item;
      })
    );
  };

  const handleKimReplies = () => {
    setRepliesDrawer(true);
  };

  const handleOpenRepliesDrawer = () => {
    setRepliesDrawer(true);
  };

  const handleCloseRepliesDrawer = () => {
    setRepliesDrawer(false);
  };

  const is_required_error = () => {
    const answeredIds = questionAnswers.map((ans) => ans._id);
    const allRequiredAnswered = questions
      .filter((q) => q.is_required)
      .every((reqQuestion) => answeredIds.includes(reqQuestion._id));
    if (!allRequiredAnswered) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e, val) => {
    e.preventDefault();
    if (is_required_error()) {
      enqueueSnackbar("Questions with * must be answered", {
        variant: "error",
      });
      return;
    }
    if (val) {
      setIsLoadingAll(true);
    } else {
      setIsLoadingForm(true);
    }
    let postData = {
      created_for: questions[0]?.created_for,
      created_for_id: created_for_id || "",
      question_answer_array: questionAnswers,
      self_image_status: val ? "completed" : "incompleted",
    };
    if (for_mission) {
      postData.other_module_id = mission_schedule_id;
      postData.created_for_id = params.created_for_id ?? params.mission__id;
    }
    const result = await add_answers_api(postData);
    if (result.code === 200) {
      if (onSubmitSuccess) {
        onSubmitSuccess({ result });
      } else {
        if (questionConfigration?.is_show_thank_you_page) {
          navigate(`${window.location.pathname}/thank-you`, {
            state: {
              question_configration: questionConfigration,
              module_title: moduleTitle,
            },
          });
          get_questions_list();
        }
      }
      if (!for_mission) {
        setIsLoadingAll(false);
        setIsLoadingForm(false);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };
  const handleSubmitSpecific = async (e, index, question) => {
    e.preventDefault();
    handle_update_questions(index, "isLoading", true);

    let find_answer = questionAnswers.find(
      (answer) => answer._id == question._id
    );
    let postData = {
      created_for: created_for,
      created_for_id: created_for_id || "",
      question_answer_object: find_answer,
    };
    if (for_mission) {
      postData.other_module_id = mission_schedule_id;
      postData.created_for_id = params.created_for_id ?? params.mission__id;
    }
    const result = await add_specific_answers_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handle_update_questions(index, "isLoading", false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      handle_update_questions(index, "isLoading", false);
    }
  };

  const handleDeleteDocument = async (question_id, image_path) => {
    if (isDisabled) return;
    const formData = new FormData();
    formData.append("image", image_path);

    const result = await delete_image_on_s3_for_chat(formData);
    if (result.code === 200) {
      setQuestionsAnswers((prev) => {
        return prev.map((old_question) => {
          if (old_question._id == question_id) {
            delete old_question.document_url;
          }
          return old_question;
        });
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (question_answers) {
      setQuestionsAnswers(question_answers);
      setQuestions(
        questions_list.map((question, index) => ({
          ...question,
          isExpanded: index === 0, // Open the first question by default
        }))
      );
      setIsLoading(false);
    } else {
      get_questions_list();
    }
  }, [created_for_id]);

  const replacedString = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo?.first_name);
    } else {
      return "";
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  function convertHtmlToString(html) {
    return ReactDOMServer.renderToString(html);
  }

  const handleGeneratePdf = () => {
    setIsDonwloadingPdf(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    const html = (
      <div className="text-dark generating-pdf">
        <div className="d-flex justify-content-center">
          <img src={appLogoIcon} alt="" srcset="" className="logo-dd" />
        </div>
        {is_inline ? (
          <div className="d-flex justify-content-between">
            <div className="last-submit-date">
              Questions For:{" "}
              {created_title || show_proper_words(questions[0]?.created_for)}
            </div>
            <div className="last-submit-date">
              {historyTitle || dd_date_format(lastSubmitDate.updatedAt)}
            </div>
          </div>
        ) : (
          <>
            <div className="last-submit-date text-center">
              Questions For:{" "}
              {created_title || show_proper_words(questions[0]?.created_for)}
            </div>
            <div className="last-submit-date text-center mt-1">
              {historyTitle || dd_date_format(lastSubmitDate.updatedAt)}
            </div>
          </>
        )}
        <h2 className="general-question-heading"></h2>
        {questions.map((question, index) => {
          let find_answer = questionAnswers.find(
            (answer) => answer._id == question._id
          );
          return (
            <>
              <h3
                className={`question-statement-for-pdf ${
                  index == 0 ? "mt-0" : "mt-2"
                }`}
              >
                Question Statement:
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: question.question_statement,
                }}
                className="question-statement-for-pdf-text"
              />
              <h3 className="question-statement-for-pdf">Answer:</h3>
              {question.question_type == "checkbox" ? (
                <div class="control checkbox-buttons mt-1">
                  {question?.options.map((option) => {
                    let checkbox_answer = find_answer?.answer_statement?.find(
                      (old_answer) => old_answer == option
                    );
                    return (
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                          checked={checkbox_answer ? true : false}
                        />
                        <span>{option}</span>
                        <br />
                      </div>
                    );
                  })}
                </div>
              ) : question.question_type == "scaling" ? (
                <div className="scaling-questions d-flex mt-3">
                  {[...Array(question.scaling_max)].map((_, index) => {
                    return (
                      <div
                        className={`scaling-count ${
                          index < find_answer?.answer_statement
                            ? "scaling-count-selected"
                            : ""
                        }`}
                      >
                        <p>{index + 1}</p>
                      </div>
                    );
                  })}
                </div>
              ) : question.question_type == "mcq" ? (
                <div class="control radio-buttons mt-1">
                  {question?.options.map((option) => {
                    return (
                      <div className="d-flex">
                        <input
                          type="radio"
                          name="answer"
                          checked={find_answer?.answer_statement == option}
                        />
                        <span>{option}</span>
                        <br />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-area-answer">
                  <p>
                    {find_answer?.answer_statement
                      ? find_answer?.answer_statement
                      : "No Answer Given"}
                  </p>
                </div>
              )}
              {/* {question.is_document_allowed && find_answer?.document_url && (
                <div className="text-end">
                  <a
                    href={s3baseUrl + find_answer.document_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="view-document-in-pdf"
                  >
                    View Document
                  </a>
                </div>
              )} */}
            </>
          );
        })}
      </div>
    );
    const html2 = convertHtmlToString(html);
    doc.html(html2, {
      margin: [15, 25, 20, 20],
      autoPaging: "text",
      callback: (doc) => {
        doc.save();
        setIsDonwloadingPdf(false);
      },
    });
  };

  return (
    <div className={`container ${className || ""}`}>
      <CustomDrawer
        isOpenDrawer={responceDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`Your Dynamite Response`}
        paperProps={{ className: "general-questions-list-drawer" }}
        componentToPassDown={
          <KimResponce question={question} data={comments} />
        }
      />
      {!remove_back_button && (
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            {moduleTitle && (
              <span className="sale-page-title">{moduleTitle}</span>
            )}
          </div>
        </div>
      )}
      <div className="row justify-content-center pb-5">
        <div className="col-12 mt-2 general-questions-top-description">
          {questionConfigration &&
            questionConfigration.questions_top_description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: questionConfigration?.questions_top_description,
                }}
                className="questions_top_description"
              ></div>
            )}
          <div
            className={`col-12 ${
              questionConfigration?.questions_top_description ? "mt-4" : ""
            }`}
          >
            {questions.length > 0
              ? questions.map((question, i) => {
                  let find_answer = questionAnswers.find(
                    (answer) => answer._id == question._id
                  );

                  return (
                    <div className="card mb-2" key={question.id}>
                      <Accordion expanded={question.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(i, !question.isExpanded)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-summary qustion-section-summary qustion-color"
                        >
                          <div className="d-flex goal_statement_yes_icons justify-content-between w-100">
                            <div className="d-flex">
                              <span className="required-asterisk me-1">
                                {question.is_required && "*"}
                              </span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replacedString(
                                    question.question_statement
                                  ),
                                }}
                              ></div>
                            </div>

                            <img
                              src={
                                find_answer?.answer_statement
                                  ? goal_statement_yes_icons_new1
                                  : goal_statement_no_new_icons
                              }
                              className="float-end"
                              alt=""
                            />
                          </div>
                        </AccordionSummary>

                        <AccordionDetails>
                          {question.question_type == "mcq" ? (
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="radio-buttons-group"
                                value={find_answer?.answer_statement}
                                onChange={(e) =>
                                  handleChangeAnswer(
                                    question._id,
                                    e.target.value
                                  )
                                }
                              >
                                {question?.options.map((option, index) => (
                                  <div
                                    className={`question-answer  ${
                                      find_answer?.answer_statement
                                        ? option ===
                                          find_answer?.answer_statement
                                          ? "selectedAnswer"
                                          : ""
                                        : ""
                                    }`}
                                    key={option.id}
                                  >
                                    <Tooltip
                                      title={
                                        isDisabled && disableTooltip
                                          ? disableTooltip
                                          : ""
                                      }
                                    >
                                      <FormControlLabel
                                        value={option}
                                        id={`capsule${index}`}
                                        className="answer"
                                        control={
                                          <Radio disabled={isDisabled} />
                                        }
                                        label={option}
                                      />
                                    </Tooltip>
                                  </div>
                                ))}
                              </RadioGroup>
                            </FormControl>
                          ) : question.question_type == "checkbox" ? (
                            <FormControl>
                              <FormGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="radio-buttons-group"
                                value={question?.answer}
                                onChange={(e) =>
                                  handleChangeAnswerCheckbox(
                                    question._id,
                                    e.target.value
                                  )
                                }
                              >
                                {question?.options.map((option, index) => {
                                  let answer_statement =
                                    find_answer?.answer_statement;
                                  let checkbox_answer = answer_statement?.find(
                                    (ans) => ans === option
                                  );
                                  return (
                                    <div
                                      className={`question-answer`}
                                      key={option.id}
                                    >
                                      <Tooltip
                                        title={
                                          isDisabled && disableTooltip
                                            ? disableTooltip
                                            : ""
                                        }
                                      >
                                        <FormControlLabel
                                          value={option}
                                          id={`capsule${index}`}
                                          className="answer"
                                          control={
                                            <Checkbox
                                              disabled={isDisabled}
                                              checked={Boolean(checkbox_answer)}
                                            />
                                          }
                                          label={option}
                                        />
                                      </Tooltip>
                                    </div>
                                  );
                                })}
                              </FormGroup>
                            </FormControl>
                          ) : question.question_type == "scaling" ? (
                            <FormControl>
                              <ul className="list-unstyled pt-1 mb-0">
                                <li className="row">
                                  <div className="col-12">
                                    <GeneralQuestionStarRating
                                      question={question}
                                      handleAnswer={handleChangeAnswer}
                                      find_answer={find_answer}
                                      isDisabled={isDisabled}
                                      disableTooltip={disableTooltip}
                                    />
                                  </div>
                                </li>
                              </ul>
                            </FormControl>
                          ) : (
                            <Tooltip
                              title={
                                isDisabled && disableTooltip
                                  ? disableTooltip
                                  : ""
                              }
                            >
                              <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder={question.question_placeholder}
                                className="text-color textarea-autosize"
                                value={find_answer?.answer_statement}
                                sx={{ color: "white" }}
                                disabled={isDisabled}
                                minRows={5}
                                onChange={(e) =>
                                  handleChangeAnswer(
                                    question._id,
                                    e.target.value
                                  )
                                }
                                style={{
                                  width: "100%",
                                  borderRadius: "11px",
                                  background: "transparent",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  paddingLeft: "15px",
                                  color: "white",
                                  fontSize: "15px",
                                  border: "2px solid rgba(71, 66, 34, 0.3)",
                                }}
                              />
                            </Tooltip>
                          )}
                          <div className="col-12 d-flex justify-content-between flex-column flex-md-row pt-2">
                            {created_for == "self_image" &&
                            find_answer?.show_replies ? (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleKimResponce(
                                    find_answer,
                                    replacedString(question.question_statement)
                                  );
                                }}
                                className="small-contained-button button-link preview mt-2"
                              >
                                {`Your Dynamite Response`}
                              </button>
                            ) : (
                              <div></div>
                            )}
                            <div className="d-flex text-center text-md-end">
                              {!prevent_single_answer && (
                                <Tooltip
                                  title={
                                    isDisabled && disableTooltip
                                      ? disableTooltip
                                      : ""
                                  }
                                >
                                  <button
                                    className={`small-contained-button save-and-next mt-2 me-2 ${
                                      isDisabled ? "show-btn-disabled" : ""
                                    }`}
                                    onClick={(e) => {
                                      handleSubmitSpecific(e, i, question);
                                    }}
                                    disabled={isDisabled || question.isLoading}
                                  >
                                    {question.isLoading ? "Saving..." : "Save"}
                                  </button>
                                </Tooltip>
                              )}
                              {question.is_document_allowed && (
                                <>
                                  {find_answer?.document_url ? (
                                    <button className="small-contained-button button-link preview mt-2">
                                      <span
                                        onClick={(e) => {
                                          handleDeleteDocument(
                                            question._id,
                                            find_answer.document_url
                                          );
                                        }}
                                      >
                                        x
                                      </span>
                                      <a
                                        href={
                                          s3baseUrl + find_answer.document_url
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View Document
                                      </a>
                                    </button>
                                  ) : (
                                    !isDisabled && (
                                      <label
                                        htmlFor={`contained-button-file${i}`}
                                        className="mt-2"
                                      >
                                        <Input
                                          id={`contained-button-file${i}`}
                                          multiple
                                          type="file"
                                          name="image"
                                          className="d-none"
                                          onChange={(e) => {
                                            fileChangedHandler(question._id, e);
                                          }}
                                        />

                                        <Button
                                          className="small-contained-button"
                                          startIcon={<FileUploadIcon />}
                                          component="span"
                                        >
                                          Upload Document
                                        </Button>
                                      </label>
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })
              : ""}
          </div>
          {questions.length > 0 && (
            <>
              <div className="col-12 d-flex justify-content-between mt-3 question-buttons">
                <div className="self-button">
                  {(lastSubmitDate?.updatedAt || historyTitle) &&
                    questionAnswers?.length > 0 && (
                      <button
                        className="button black-btn"
                        onClick={handleGeneratePdf}
                        disabled={isDonwloadingPdf}
                      >
                        {isDonwloadingPdf ? "Downloading..." : "Download PDF"}
                      </button>
                    )}
                  {(!historyTitle || !hide_dynamite_reply) && (
                    <button
                      onClick={handleKimReplies}
                      className="ms-1 button black-btn"
                    >
                      {`Your Dynamite Reply`}
                    </button>
                  )}
                </div>
                {!hideSubmitButton && (
                  <div>
                    {(created_for === "90-day-questions" ||
                      created_for === "self_image") && (
                      <>
                        {selfImageCompletedStatus !== "completed" &&
                          selfImageCompletedStatus !== "responded" && (
                            <Tooltip
                              title={
                                isDisabled && disableTooltip
                                  ? disableTooltip
                                  : `Mark your ${
                                      created_for == "lesson"
                                        ? "lesson questions"
                                        : created_for == "dynamite_event_video"
                                        ? "dynamite event questions"
                                        : created_for == "90-day-question"
                                        ? "90 days questions"
                                        : created_for == "self_image"
                                        ? "self-image questions"
                                        : created_for == "mission"
                                        ? "mission questions"
                                        : "90 days questions"
                                    } completed `
                              }
                            >
                              <button
                                className={`small-contained-button me-2 ${
                                  is_required_error() ? "show-btn-disabled" : ""
                                }`}
                                disabled={
                                  isDisabled || is_required_error()
                                    ? true
                                    : isLoadingAll
                                }
                                onClick={(e) => {
                                  handleSubmit(e, "save-and-submit");
                                }}
                              >
                                {isLoadingAll
                                  ? "Submitting..."
                                  : "Save and Submit"}
                              </button>
                            </Tooltip>
                          )}
                      </>
                    )}
                    <>
                      {!is_required_error() && (
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="black-btn"
                          disabled={isLoadingForm}
                        >
                          {isLoadingForm ? "Submitting..." : "Submit"}
                        </button>
                      )}
                    </>
                  </div>
                )}
              </div>
              <div className="col-12 text-center">
                {created_for == "self_image" &&
                  (selfImageCompletedStatus === "completed" ||
                    selfImageCompletedStatus === "responded") && (
                    <div className="row mt-5">
                      <div className="col-md-8 goal-statement-completed-text">
                        <p>
                          Self Image was completed on the &nbsp;
                          {TIME_ZONE_CONVERSION(
                            selfImageCompletedDate,
                            "DD MMM YYYY",
                            userInfo.time_zone,
                            adminTimeZone
                          )}
                        </p>
                      </div>
                      {/* <div className="col-md-4 download-pdf-button-box">
                    <PDFDownloadLink
                      document={
                        <MyDocument
                          data={questionMessages}
                          completed_date={get_date_with_user_time_zone(
                            goalStatementCompletedDate,
                            "DD MMM YYYY",
                            userInfo.time_zone,
                            adminTimeZone
                          )}
                        />
                      }
                      fileName="DynamiteLifeStyle"
                      className="download-pdf-button"
                    >
                      {({ loading }) => (loading ? "Download" : "Download")}
                    </PDFDownloadLink>
                  </div> */}
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* <ReportTemplate questions={questions} questionAnswers={questionAnswers} /> */}
      <CustomDrawer
        isOpenDrawer={repliesDrawer}
        onOpenDrawer={handleOpenRepliesDrawer}
        onCloseDrawer={handleCloseRepliesDrawer}
        pageTitle={`Your Dynamite Reply`}
        paperProps={{ className: "general-questions-list-drawer" }}
        componentToPassDown={
          <KimResponce data={replies} created_for="self_image" />
        }
      />
    </div>
  );
}
