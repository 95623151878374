import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { tick, videoShowLock, warn2 } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { dd_date_format } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import LockIcon from "@mui/icons-material/Lock";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function QuestLevelsScheduleTabForDashBoard(props) {
  const { handleClick, schedules, missionDetail, missionSchedules } = props;
  const { state } = useLocation();

  const ref = useRef(null);
  const replacedString = (string, unlock_date) => {
    if (!string) return;
    return string.replace(/{unlock_date}/g, dd_date_format(unlock_date));
  };

  const replacedStringNote = (string, schedule) => {
    if (!string) return;
    return string
      .replace(/{started_at}/g, dd_date_format(schedule.started_at))
      .replace(/{end_date}/g, dd_date_format(schedule.end_date));
  };

  console.log(missionDetail.replay_days, "missionSchedulesmissionSchedules");
  return (
    <div className="row mission-levels-schedule-tab" ref={ref}>
      <div className={`${screen.width > 767 ? "card" : ""}`}>
        <div className="col-12">
          {schedules.map((schedule) => {
            const today = moment();
            const startedAt = moment(schedule.started_at);
            let tooltip_title = replacedStringNote(
              missionDetail?.content_settings?.missed_schedule_description,
              schedule
            );
            return (
              <div
                className="position-relative"
                key={schedule._id}
                id={schedule._id}
              >
                <Tooltip
                  title={
                    schedule.allowed_status === "missed" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tooltip_title,
                        }}
                        className="schedule_completed_description"
                      />
                    ) : missionDetail.mission_status === "not_started" ? (
                      "Please start Quest first !"
                    ) : schedule.schedule_status === "locked" ? (
                      missionDetail.mission_status === "not_started" ? (
                        "Start Quest before unlock schedule"
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  }
                >
                  <div
                    className={` row schedule-click mb-b ${
                      schedule._id === missionSchedules?._id
                        ? "in-progress-schedule"
                        : ""
                    }`}
                    key={schedule.title}
                    onClick={() => handleClick(schedule)}
                  >
                    <div className="col-3 pe-0 ps-0">
                      {schedule.schedule_status === "locked" && (
                        <div className="lock-icon-journey-main-container-locked">
                          <span className="lock-icon-journey-main">
                            <LockIcon
                              style={{ fontSize: "22px", color: "black" }}
                            />
                          </span>
                        </div>
                      )}
                      {schedule?.allowed_status == "warning" && (
                        <div className="lock-icon-journey-main-container-locked">
                          <span className="lock-icon-journey-main">
                            <ErrorOutlineIcon
                              style={{ fontSize: "22px", color: "black" }}
                            />
                          </span>
                        </div>
                      )}
                      {schedule.schedule_status === "completed" && (
                        <div className="lock-icon-journey-main-container-complete-schedule">
                          <span className="lock-icon-journey-main">
                            <span className="lock-icon-journey-main">
                              <div className="completed-images mt-3">
                                <img src={tick} alt="Completed" />
                              </div>
                            </span>
                          </span>
                        </div>
                      )}
                      <img
                        src={s3baseUrl + schedule?.image?.thumbnail_2}
                        className="img-quest"
                      />
                    </div>
                    <div className="col-9">
                      <div className="d-flex justify-content-between">
                        <h6 style={{ fontSize: "16px" }}>
                          {schedule.main_heading}
                        </h6>
                      </div>
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        {schedule.short_description}
                      </p>
                      {/* {missionDetail.content_settings?.unlock_date_statement &&
                        missionDetail.mission_status === "in_progress" &&
                        schedule.schedule_status == "locked" && (
                          <p
                            className="unlock-date-note"
                            style={{ fontSize: "12px" }}
                          >
                            {htmlDecode(
                              replacedString(
                                missionDetail.content_settings
                                  ?.unlock_date_statement,
                                schedule.started_at
                              )
                            )}
                          </p>
                        )} */}
                    </div>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
