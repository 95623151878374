import { Avatar, CircularProgress, Toolbar, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { s3baseUrl } from "src/config/config";
import {
  mission_level_detail_New_api,
  mission_levels_list_api,
  mission_levels_list_api_v1,
} from "src/DAL/MissionLevels/MissionLevels";
import LockIcon from "@mui/icons-material/Lock";
import { htmlDecode } from "src/utils/convertHtml";
import { cointlogo } from "src/assets";
import { ReactVideoPlayer } from "src/components";
import MissionCardForReport from "../MissionControlNew/components/MissionCardForReport";
import MissionCardCompleteForReport from "../MissionControlNew/components/MissionCardCompleteForReport";
import { handle_change_page } from "src/utils/constants";
import CreatePostMission from "./CreatePostMission";
import MissionReport from "./MissionReport";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionComplete({
  missionDetail,
  book_a_call_button_settings,
  membership_upgrade_button_setting,
  isLoadBookACallButton,
  isLoadMembershipUpgradeButton,
  setIsLoadBookACallButton,
  setIsLoadMembershipUpgradeButton,
  missionResultData,
  missionBadges,
  rewardData,
  listType,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { created_for_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [missionLevels, setMissionLevels] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [mcqData, setMcqData] = useState([]);
  const [graphStructure, setGraphStructure] = useState([]);
  const [content_settings, setContentSettings] = useState({});
  const [missionDashboaerd, setmissionDashboaerd] = useState(null);
  const [onScreenQuestion, setOnonScreenQuestion] = useState([]);
  const [BookACallSettings, setBookACallSettings] = useState(null);
  const [upgradeButton, setUpgradeButton] = useState(false);

  const get_mission_levels = async () => {
    const result = await mission_levels_list_api_v1();
    if (result.code === 200) {
      console.log(result, "resultresult");
      setMissionLevels(result.level_badges);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_mission_detail = async (value) => {
    setIsLoading(true);
    let PostData = {
      mission_id: created_for_id,
      tab: "report",
      data: "all",
    };
    const result = await mission_level_detail_New_api(PostData);
    if (result.code == 200) {
      console.log(result, "result");
      setContentSettings(result.mission.content_settings);
      setOnonScreenQuestion(result?.on_screen_graph_data);
      setBookACallSettings(result?.book_a_call_button_setting);
      setMcqData(result.mcq_graph_data);
      if (result.structured_graph_data.length > 0) {
        setGraphStructure(result.structured_graph_data);
      }
      setReportData(result.report_data);
      let data = {
        other_missions: result.other_missions,
        user_continue_missions: result.user_continue_missions,
      };
      setGraphData(result.graph_data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    get_mission_levels();
    get_mission_detail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid mission-levels">
      <div className="row">
        {missionResultData.schedule_missed ? (
          <div className="col-8">
            {missionDetail?.content_settings
              ?.mission_complete_video_for_missed_schedule ? (
              <>
                {missionDetail.content_settings
                  .mission_complete_video_for_missed_schedule && (
                  <ReactVideoPlayer
                    url={
                      missionDetail.content_settings
                        .mission_complete_video_for_missed_schedule
                    }
                  />
                )}
              </>
            ) : (
              <img
                src={s3baseUrl + missionDetail?.image?.thumbnail_1}
                className="img-fluid w-100"
              />
            )}
          </div>
        ) : (
          <div className="col-8">
            {missionDetail?.content_settings?.mission_complete_video ? (
              <>
                {missionDetail?.content_settings?.mission_complete_video && (
                  <ReactVideoPlayer
                    url={
                      missionDetail?.content_settings?.mission_complete_video
                    }
                  />
                )}
              </>
            ) : (
              <img
                src={s3baseUrl + missionDetail?.image?.thumbnail_1}
                className="img-fluid w-100"
              />
            )}
          </div>
        )}
        <div className="col-4 ps-0">
          <MissionCardCompleteForReport
            missionDetail={missionDetail}
            missionBadges={missionBadges}
            hideDuration={true}
            // missionDashboaerd={missionDashboaerd}
          />
          {book_a_call_button_settings?.show_book_call_button &&
            book_a_call_button_settings?.book_call_button_url && (
              <button
                className="small-contained-button mt-2 me-2"
                style={{
                  padding: "10px 15px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  width: "100%",
                }}
                onClick={() =>
                  handle_change_page(
                    book_a_call_button_settings?.book_call_button_url,
                    setIsLoadBookACallButton
                  )
                }
              >
                {isLoadBookACallButton
                  ? "Please wait..."
                  : book_a_call_button_settings.book_call_button_text}
              </button>
            )}
          {membership_upgrade_button_setting?.show_membership_button &&
            membership_upgrade_button_setting?.upgrade_button_url && (
              <button
                className="small-contained-button mt-2"
                style={{
                  padding: "10px 15px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  width: "100%",
                }}
                onClick={() =>
                  handle_change_page(
                    membership_upgrade_button_setting?.upgrade_button_url,
                    setIsLoadMembershipUpgradeButton
                  )
                }
              >
                {isLoadMembershipUpgradeButton
                  ? "Please wait..."
                  : membership_upgrade_button_setting?.upgrade_button_text}
              </button>
            )}
          {!membership_upgrade_button_setting?.show_membership_button &&
            missionResultData?.mission?.content_settings
              ?.allow_user_to_create_reward_feed && (
              <button
                className="small-contained-button mt-2"
                style={{
                  padding: "10px 15px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  width: "100%",
                }}
                onClick={() => setOpenCreateModal(true)}
              >
                {missionResultData?.mission?.content_settings
                  ?.reward_post_button_text || "Share With Community"}
              </button>
            )}
        </div>{" "}
        {missionDetail?.content_settings?.complete_mission_description && (
          <div className="mt-5">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  missionDetail?.content_settings?.complete_mission_description,
              }}
              className="pe-3"
            ></div>
          </div>
        )}
        <MissionReport
          reportData={reportData}
          graphData={graphData}
          mcqData={mcqData}
          graphStructure={graphStructure}
          content_settings={content_settings}
          missionDetail={missionDetail}
          missionDashboaerd={missionDashboaerd}
          onScreenQuestion={onScreenQuestion}
          hideUpperContent={true}
          BookACallSettings={BookACallSettings}
          upgradeButton={upgradeButton}
          setUpgradeButton={setUpgradeButton}
          missionBadges={missionBadges}
        />
        <CreatePostMission
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          rewardData={rewardData}
          listType={listType}
        />
      </div>
    </div>
  );
}
