import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { s3baseUrl } from "src/config/config";
import LockIcon from "@mui/icons-material/Lock";
import { mission_levels_new } from "src/DAL/MissionLevels/MissionLevels";
import { handle_change_page } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { tick } from "src/assets";
import DoneIcon from "@mui/icons-material/Done";

export default function UsersCompletedChallenges({ type, dataArray }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [upgradeButton, setUpgradeButton] = useState(false);
  const [missionLevels, setMissionLevels] = useState([]);
  const [paddingMap, setPaddingMap] = useState({});
  const get_mission_levels = (dataArray) => {
    let mission = [];
    const data = dataArray.map((item, index) => {
      mission.push(item);
    });

    setMissionLevels(mission);
  };
  const handleDetail = (object) => {
    return;
    if (object.challange_type == "lifeScript") {
      navigate(`/money-script/${object._id}`);
    } else if (object.challange_type == "visioneering") {
      navigate(`/visioneering/${object._id}`);
    } else {
      navigate(`/meditations/${object._id}`);
    }
  };

  useEffect(() => {
    get_mission_levels(dataArray);
  }, [dataArray]);

  return (
    <>
      <div className="mission-levels mb-3">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="challenge-video-card mt-3">
              {missionLevels.length > 0 && (
                <div className="row">
                  {missionLevels.map((mission) => {
                    let note_id = `note_${mission._id}`;
                    return (
                      <div
                        className="col-12 col-md-6 col-lg-4 mb-3 position-relative"
                        key={mission._id}
                      >
                        <div
                          className="card mission-level-card"
                          style={{
                            paddingBottom: paddingMap[note_id] || 0,
                          }}
                          onClick={() => handleDetail(mission)}
                        >
                          <div className="mission-days text-capitalize">
                            {type == "quest" ? (
                              <>
                                {mission?.is_expired ? (
                                  "Expired"
                                ) : (
                                  <>
                                    {mission.number_of_days} Days (
                                    {mission?.challange_type})
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {mission.number_of_days} Days (
                                {mission?.challange_type})
                              </>
                            )}
                          </div>
                          <img
                            alt={mission.title}
                            src={
                              s3baseUrl + mission.challange_image.thumbnail_2
                            }
                          />
                          <div className="p-3">
                            <h3>{htmlDecode(mission.title)}</h3>
                            {mission.short_description && (
                              <p className="mui-custom-card-description">
                                {htmlDecode(mission.short_description)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {missionLevels.length == 0 && (
              <RecordNotFound title="No Data Found" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
