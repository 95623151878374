import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  Grid,
  Box,
  Tooltip,
  Avatar,
} from "@mui/material";
import { achieve, MissionCoin, rewards } from "src/assets";
import { s3baseUrl } from "src/config/config";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import InfoPopup from "./InfoPopup";
import RewardsPopup from "./RewardsPopup";
import { fShortenNumber } from "src/utils/formatNumber";
import moment from "moment";

const QuestCardCompleteForReport = ({
  missionDetail,
  missionDashboaerd,
  missionBadges,
  hideDuration,
  hideEranedBadges,
}) => {
  const [showInfoPopup, setShowInfoPopup] = useState([]);

  function formatDate(isoString) {
    return moment.utc(isoString).format("DD MMM");
  }
  console.log(missionBadges, "mission_detail_new");
  return (
    <>
      <h4
        className="title-source-journey mb-2 mt-1"
        style={{
          color: "#ffd889",
        }}
      >
        Rewards & Badges{" "}
      </h4>

      {(missionDetail?.rewarded_coins !== 0 ||
        missionDetail?.attracted_coins !== 0 ||
        missionDetail.badge_configration.length > 0) && (
        <Card
          style={{
            backgroundColor: "#1c1c1c",
            color: "#fff",
            padding: "15px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            position: "relative",
            overflow: "hidden",
            paddingBottom: "15px",
            marginBottom: "10px",
          }}
        >
          <div className="header bg-mission ">
            <div className="">
              {missionDetail?.rewarded_coins !== 0 && (
                <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between bg-info-of-mission-2 transparent-background">
                  <div className="title-mission d-flex">
                    <img
                      src={MissionCoin}
                      style={{
                        width: "20px",
                      }}
                    />
                    <span
                      className="ms-1"
                      style={{
                        color: "white",
                      }}
                    >
                      Achievable Coins
                    </span>
                  </div>
                  <div className="info-of-mission-text">
                    {fShortenNumber(missionDetail?.rewarded_coins)}
                  </div>
                </div>
              )}
              {missionDetail?.attracted_coins !== 0 && (
                <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between bg-info-of-mission-2 mt-2 transparent-background">
                  <div className="title-mission d-flex">
                    <img
                      src={MissionCoin}
                      style={{
                        width: "20px",
                      }}
                    />
                    <span
                      className="ms-1"
                      style={{
                        color: "white",
                      }}
                    >
                      Coins Earned
                    </span>
                  </div>

                  <div className="info-of-mission-text">
                    {fShortenNumber(missionDetail?.attracted_coins)}
                  </div>
                </div>
              )}
              {missionDetail.badge_configration.length > 0 && (
                <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between bg-info-of-mission-2 mt-2 transparent-background">
                  <div className="title-mission d-flex">
                    <img
                      src={rewards}
                      style={{
                        width: "20px",
                      }}
                    />
                    <span
                      className="ms-1"
                      style={{
                        color: "white",
                      }}
                    >
                      Achievable Badges
                    </span>
                  </div>
                  {missionDetail.badge_configration.length > 0 && (
                    <div className="info-of-mission-text">
                      <div className="d-flex align-items-center">
                        <Tooltip
                          title={
                            missionDetail.badge_configration[0].title +
                            " " +
                            missionDetail.badge_configration[0].no_of_badges +
                            "x"
                          }
                        >
                          <img
                            src={
                              s3baseUrl +
                              missionDetail.badge_configration[0]?.general_icon
                                ?.thumbnail_1
                            }
                            alt=""
                            style={{
                              width: "24px",
                              borderRadius: "2px",
                            }}
                            className="badge-image"
                          />
                        </Tooltip>
                        {missionDetail.badge_configration.length - 1 > 0 && (
                          <Tooltip title={"View More"}>
                            <span
                              className="ms-1"
                              style={{
                                cursor: "pointer",
                                color: " #f0c26a",
                              }}
                              onClick={() =>
                                setShowInfoPopup(
                                  missionDetail.badge_configration
                                )
                              }
                            >
                              +{missionDetail.badge_configration.length - 1}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!hideEranedBadges && (
                <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between align-items-center bg-info-of-mission-2 mt-2 transparent-background">
                  <div className="title-mission d-flex">
                    <img
                      src={rewards}
                      style={{
                        width: "20px",
                      }}
                    />
                    <span
                      className="ms-1"
                      style={{
                        color: "white",
                      }}
                    >
                      Badges Earned
                    </span>
                  </div>
                  {missionBadges && missionBadges.length > 0 ? (
                    <div className="info-of-mission-text">
                      <div className="d-flex align-items-center">
                        <Tooltip
                          title={
                            missionBadges[0].title +
                            " " +
                            missionBadges[0].no_of_badges +
                            "x"
                          }
                        >
                          <img
                            src={
                              s3baseUrl +
                              missionBadges[0]?.general_icon?.thumbnail_1
                            }
                            alt=""
                            style={{
                              width: "24px",
                              borderRadius: "2px",
                            }}
                            className="badge-image"
                          />
                        </Tooltip>
                        {missionBadges.length - 1 > 0 && (
                          <Tooltip title={"View More"}>
                            <span
                              className="ms-1"
                              style={{
                                cursor: "pointer",
                                color: " #f0c26a",
                              }}
                              onClick={() => setShowInfoPopup(missionBadges)}
                            >
                              +{missionBadges.length - 1}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ) : (
                    <span
                      className="ms-1"
                      style={{
                        cursor: "pointer",
                        color: "#959595",
                        fontSize: "10px",
                      }}
                    >
                      No Badges Earned
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </Card>
      )}
      {!hideDuration && (
        <Card
          style={{
            backgroundColor: "#1c1c1c",
            color: "#fff",
            padding: "15px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            position: "relative",
            overflow: "hidden",
            marginBottom: "10px",
          }}
        >
          <div className="header bg-mission ">
            <div className="">
              <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between   bg-info-of-mission-2">
                <div className="title-mission d-flex align-items-center ">
                  <img
                    src={
                      "https://dd-feature-app-bucket.s3.amazonaws.com/upload/816051a3-2434-4897-a252-af2aead806ce.png"
                    }
                    style={{
                      width: "17px",
                      height: "18px",
                    }}
                  />
                  <span
                    className="ms-1"
                    style={{
                      color: "white",
                    }}
                  >
                    Quest Duration
                  </span>
                </div>

                <div className="info-of-mission-text">
                  {missionDetail?.mission_duration} days
                </div>
              </div>
            </div>
          </div>
          <div className="header bg-mission mt-2">
            <div className="">
              <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between   bg-info-of-mission-2">
                <div className="title-mission d-flex align-items-center">
                  <img
                    src={
                      "https://dd-feature-app-bucket.s3.amazonaws.com/upload/816051a3-2434-4897-a252-af2aead806ce.png"
                    }
                    style={{
                      width: "17px",
                      height: "18px",
                    }}
                  />
                  <span
                    className="ms-1"
                    style={{
                      color: "white",
                    }}
                  >
                    Dates
                  </span>
                </div>
                <div className="info-of-mission-text">
                  {formatDate(missionDetail.start_date)} -{" "}
                  {formatDate(missionDetail.end_date)}
                </div>
              </div>
            </div>
          </div>
          <div className="header bg-mission ">
            <div className="mt-2">
              <div className="bg-info-of-mission text-center w-100 d-flex justify-content-between   bg-info-of-mission-2">
                <div className="title-mission d-flex align-items-center">
                  <img
                    src={
                      "https://dd-feature-app-bucket.s3.amazonaws.com/upload/816051a3-2434-4897-a252-af2aead806ce.png"
                    }
                    style={{
                      width: "17px",
                      height: "18px",
                    }}
                  />
                  <span
                    className="ms-1"
                    style={{
                      color: "white",
                    }}
                  >
                    Replay Access
                  </span>
                </div>
                <div className="info-of-mission-text">
                  {" "}
                  {missionDetail?.replay_days} days
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}

      <GeneralModelBox
        open={Boolean(showInfoPopup.length > 0)}
        setOpen={setShowInfoPopup}
        className={"challenge-video-days-questions-modal-new"}
        componentToPassDown={
          <RewardsPopup
            handleCross={() => setShowInfoPopup([])}
            showInfoPopup={showInfoPopup}
          />
        }
      />
    </>
  );
};

export default QuestCardCompleteForReport;
