import { invokeApi } from "src/bl_libs/invokeApi";
export const _get_website_page = async (data) => {
  const requestObj = {
    path: `app/get_website_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const pixel_button_events = async (data) => {
  const requestObj = {
    path: `api/sale_page/meta_conversion_api`,
    method: "POST",

    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_currency = async (data) => {
  const requestObj = {
    path: `app/convert/currency`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _token_verification_for_upgrade_portal = async (url_token) => {
  const requestObj = {
    path: `api/member/token_verification_for_upgrade_portal`,
    method: "GET",
    headers: {
      "x-sh-auth": url_token,
    },
  };
  return invokeApi(requestObj);
};

export const get_web_intent_client_secret_for_one_time = async (plan_id) => {
  const requestObj = {
    path: `app/pay_one_time_by_web`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: plan_id,
  };
  return invokeApi(requestObj);
};

export const add_free_member_by_web = async (data) => {
  const requestObj = {
    path: `app/add_free_member_by_web`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const register_through_bank_api = async (data) => {
  const requestObj = {
    path: `app/add_free_member_by_web`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const login_member_by_payment_popup = async (data) => {
  const requestObj = {
    path: `api/member/login_by_web`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const login_member_by_payment_popup_for_shop = async (data) => {
  const requestObj = {
    path: `app/login_by_web_for/storehouse`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const login_for_booking_popup = async (data) => {
  const requestObj = {
    path: `api/member/login_for_booking`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _register_by_web = async (data) => {
  const requestObj = {
    path: `app/register_by_web`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _check_email_exist = async (data) => {
  const requestObj = {
    path: `app/check_member_existance`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _check_member_existance_and_data = async (data) => {
  const requestObj = {
    path: `app/check_member_existance_and_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const paymentPlanDetail = async (plan_id) => {
  const requestObj = {
    path: `api/payment_plan/detail_payment_plan_for_web/${plan_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const confirm_one_time_payment_for_web = async (data) => {
  const requestObj = {
    path: `app/change_one_time_payment_status_by_web_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const pay_now_for_subscription_web = async (data) => {
  const requestObj = {
    path: `app/pay_now_by_web`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirm_subscription_incomplete_for_web = async (data) => {
  const requestObj = {
    path: `app/confirm_subscription_incomplete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const send_contact_message = async (data) => {
  const requestObj = {
    path: `app/contact_us`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const slots_list_api = async (data, affiliate_url_name) => {
  const requestObj = {
    path: `app/slots/list?affiliate_url_name=${
      affiliate_url_name ? affiliate_url_name : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const slots_list_api_v4 = async (data, affiliate_url_name) => {
  const requestObj = {
    path: `app/slots/list/v4?affiliate_url_name=${
      affiliate_url_name ? affiliate_url_name : ""
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_booking_api = async (data) => {
  const requestObj = {
    path: `app/booking/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_booking_api_v4 = async (data) => {
  const requestObj = {
    path: `app/booking/add/v4`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_free_member_by_website = async (data) => {
  const requestObj = {
    path: `app/add_free_member_by_web/without_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const sign_agreement_api = async (data) => {
  const requestObj = {
    path: `app/sign_agreement`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const check_ticket_availability_api = async (data) => {
  const requestObj = {
    path: `app/check_ticket_availability`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const check_user_plan_purchase_api = async (data) => {
  const requestObj = {
    path: `app/plan_purchase/check`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//

export const pay_now_for_subscription_web_v1 = async (data) => {
  const requestObj = {
    path: `api/mission/recurring_payment/quest`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirm_subscription_incomplete_for_web_V1 = async (data) => {
  const requestObj = {
    path: `app/confirm_subscription_incomplete/multiple_plans`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_web_intent_client_secret_for_one_time_v1 = async (data) => {
  const requestObj = {
    path: `api/mission/payment/quest`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const show_browser_consoles_on_backend = async (data) => {
  const requestObj = {
    path: `app/console_dev`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirm_one_time_payment_for_web_v1 = async (data) => {
  const requestObj = {
    path: `api/mission/start/quest_new`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const confirm_subscription_from_page_redirect = async (data) => {
  const requestObj = {
    path: `api/paypal_payment/process_payment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
