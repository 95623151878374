import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  add_feed_comment,
  edit_feed_comment,
} from "src/DAL/Community/Comments";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import EmojiPicker from "src/components/GeneralComponents/EmojiPicker";
import { useRef } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { s3baseUrl } from "src/config/config";
import MentionEditor from "./components/MentionEditor";
import { decryptToken } from "src/utils/constants";

export default function AddComment({
  post_id,
  feedsData,
  formAction,
  setFormAction,
  setShowAddComment,
  feed,
  event_id,
  feeds_type,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState("");
  const [previews, setPreviews] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [image, setImage] = useState("");
  const [commentId, setCommentId] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [updateValue, setUpdateValue] = useState({});
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const mentionEditorRef = useRef(null);
  const settings = useContentSetting();
  const inputRef = useRef(null);
  const { socket } = settings;

  const handleRemove = () => {
    setPreviews("");
    setOldImage("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  //Adding Category
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("feed", post_id);
    formData.append("message", state);
    formData.append("mentioned_users", JSON.stringify(mentionedUsers));
    if (image) {
      formData.append("image", image);
    }
    const result = await add_feed_comment(formData);
    if (result.code === 200) {
      handleRemove();
      setState("");
      mentionEditorRef.current?.clearContent();
      setShowEmojiPicker(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("message", state);
    formData.append("mentioned_users", JSON.stringify(mentionedUsers));
    if (image) {
      formData.append("image", image);
    } else if (feedsData.image?.thumbnail_2 && !oldImage) {
      formData.append("is_image_deleted", true);
    }
    const result = await edit_feed_comment(formData, commentId);
    if (result.code === 200) {
      setState("");
      mentionEditorRef.current?.clearContent();
      handleRemove();
      setShowEmojiPicker(false);
      setIsLoading(false);
      setFormAction("ADD");
      setShowAddComment(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCancelUpdate = (e) => {
    e.preventDefault();
    setState("");
    mentionEditorRef.current?.clearContent();
    setFormAction("ADD");
    setShowAddComment(false);
  };

  useEffect(() => {
    if (formAction === "EDIT") {
      setState(feedsData.message);
      setCommentId(feedsData._id);
      setMentionedUsers(feedsData.mentioned_users);
      if (feedsData.image?.thumbnail_2) {
        setOldImage(feedsData.image?.thumbnail_2);
      }
      setUpdateValue(feedsData);
    }
  }, [formAction]);

  return (
    <>
      <div className="new-memories add-comment-box">
        <form onSubmit={formAction === "ADD" ? handleSubmit : handleUpdate}>
          <MentionEditor
            ref={mentionEditorRef}
            setInputs={setState}
            setMentionedUsers={setMentionedUsers}
            mentionedUsers={mentionedUsers}
            feedLevel={feed.badge_level_info?._id}
            placeholder={`Write a comment…`}
            event_id={event_id}
            editorClass="comment-editor mt-2"
            addPosition={{ top: 15, left: 15 }}
            value={state}
            updateValue={updateValue}
            feeds_type={feeds_type}
          />

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mt-3 mb-2 picker-empty-div"></div>
              {previews || oldImage ? (
                <span className="preview-comment-image mt-2 ms-2">
                  <span onClick={handleRemove}>x</span>
                  {previews ? (
                    <img src={previews} />
                  ) : (
                    <img src={s3baseUrl + oldImage} />
                  )}
                </span>
              ) : (
                <>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="message-chat-upload-button"
                    style={{ display: "none" }}
                    name=""
                    onChange={handleUpload}
                  />
                  <label>
                    <Tooltip title="Add Image" placement="top">
                      <IconButton
                        onClick={() => {
                          document.getElementById(
                            "message-chat-upload-button"
                          ) &&
                            document
                              .getElementById("message-chat-upload-button")
                              .click();
                        }}
                        className="upload-image-icon-comment mb-2"
                      >
                        <AddPhotoAlternateIcon />
                      </IconButton>
                    </Tooltip>
                  </label>
                </>
              )}
            </div>
            <div className="text-end mt-3">
              {formAction === "ADD" && (
                <button className="comment-submit-button" disabled={isLoading}>
                  {isLoading ? "Saving..." : "Post Comment"}
                </button>
              )}
              {formAction === "EDIT" && (
                <div className="d-flex justify-content-end">
                  <button
                    className="me-2 comment-submit-button"
                    onClick={handleCancelUpdate}
                  >
                    Cancel
                  </button>
                  <button
                    className="comment-submit-button"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          </div>
          {showEmojiPicker && (
            <div className="col-12 mt-4 mt-lg-0 ms-auto">
              <EmojiPicker inputRef={inputRef} setInput={setState} />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
