import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { communityHandScreenshot, communityHeart } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  get_date_with_user_time_zone,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

export default function UserInfoCard({
  profile_image,
  user_name,
  date,
  activity_type,
  avatar_char,
  is_live_streaming,
  badge_level_info,
  feed,
}) {
  const { userInfo, adminTimeZone, handleViewProfile } = useContentSetting();
  return (
    <>
      <div className="post-creator user-info-card align-items-center">
        <div
          className="like-profile-image"
          style={
            badge_level_info && {
              border: `2px solid ${badge_level_info?.color_code}`,
            }
          }
        >
          <Tooltip
            title={
              feed?.action_info?.action_by !== "consultant_user"
                ? "View Profile"
                : ""
            }
          >
            <span style={{ cursor: "pointer" }}>
              <Avatar
                src={profile_image}
                alt="photoURL"
                sx={{ width: 36, height: 36 }}
                onClick={
                  feed?.action_info?.action_by !== "consultant_user"
                    ? () => handleViewProfile(feed?.action_info?.action_id)
                    : ""
                }
              >
                {avatar_char}
              </Avatar>
            </span>
          </Tooltip>
          {activity_type &&
            (activity_type === "like" || activity_type === "gratitude") && (
              <div className="tiny-like-icon">
                {activity_type === "like" ? (
                  <img src={communityHeart} alt="" />
                ) : (
                  <img src={communityHandScreenshot} alt="" />
                )}
              </div>
            )}
        </div>
        <div className="creator-name ps-2 text-start">
          <span className="d-flex feed-level">
            <h3 className="mb-0">
              {`${user_name} ${is_live_streaming ? "is live now" : ""}`}
            </h3>
            {/* {feed?.review_status === "pending" && (
              <Tooltip title="Your post is currently under review as it does not meet the required guidelines. Please wait a little while. Thank you for your patience!">
                <DoNotDisturbIcon />
              </Tooltip>
            )} */}
          </span>

          <span className="date-color">
            {TIME_ZONE_CONVERSION(
              date,
              "DD MMM YYYY [at] hh:mm A",
              adminTimeZone,
              userInfo.time_zone
            )}
          </span>
        </div>
      </div>
    </>
  );
}
