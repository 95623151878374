import React, { useEffect, useState } from "react";
import { Chip, Tooltip } from "@mui/material";
import { websiteBaseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import {
  _get_source_api_link,
  _get_source_api_link_v1,
} from "src/DAL/AffiliateLinks/AffiliateLinks";

function SalePages({ title, sourceSessions, is_mission_control }) {
  const [data, setData] = useState([]);

  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();

  const handleNavigate = (value) => {
    window.open(
      `${websiteBaseUrl}${value.sale_page_title_slug}/${userInfo.affiliate_url_name}`,
      "_blank"
    );
  };

  const handleCopiedUrl = (row) => {
    navigator.clipboard.writeText(
      `${websiteBaseUrl}${row.sale_page_title_slug}/${userInfo.affiliate_url_name}`
    );
    enqueueSnackbar("URL copied to clipboard", { variant: "success" });
  };

  const copySourceAppUrl = async (row) => {
    let copy_text = row.branch_code;
    const PostData = {
      type: row?.is_source_app ? "app" : "sale_page",
      sale_page_slug: row?.is_source_app ? "" : row.sale_page_title_slug,
    };
    if (!copy_text) {
      const result = await _get_source_api_link_v1(PostData);
      copy_text = result.url;
    }
    navigator.clipboard.writeText(copy_text);
    enqueueSnackbar("URL copied to clipboard", { variant: "success" });
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "sale_page_title", label: "Page Title" },
    {
      label: "Preview URL",
      className: "p-2",
      renderData: (row) => {
        return (
          <>
            {!row?.is_source_app ? (
              <Chip
                label="Preview"
                color="primary"
                variant="outlined"
                onClick={() => handleNavigate(row)}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      id: "action",
      label: "Copy Your Link",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
  ];
  const getBookingListing = async () => {
    const members = sourceSessions.map((member, index) => {
      return {
        ...member,
        MENU_OPTIONS: handleMenu(member),
      };
    });
    setData(members);
  };
  const handleMenu = (row) => {
    let MENU_OPTIONS = [];
    MENU_OPTIONS.push({
      label: "Copy App Link",
      icon: "material-symbols:content-copy",
      handleClick: copySourceAppUrl,
    });
    if (!row?.is_source_app) {
      MENU_OPTIONS.push({
        label: "Copy Web Link",
        icon: "material-symbols:content-copy",
        handleClick: handleCopiedUrl,
      });
    }
    return MENU_OPTIONS;
  };

  useEffect(() => {
    getBookingListing();
  }, [sourceSessions]);

  return (
    <>
      {title && (
        <div className="mb-3 mt-4">
          <h2>{title}</h2>
        </div>
      )}
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={data}
        className="card-with-background"
        hide_footer_pagination={is_mission_control}
        hide_search={is_mission_control}
        copyLink={true}
      />
    </>
  );
}

export default SalePages;
