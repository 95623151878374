import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const get_community_content = async (path) => {
  const requestObj = {
    path: path || `api/home/for_client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_feed_activity_list = async (data) => {
  const requestObj = {
    path: `api/feeds/feed_activity_detail?page=0&limit=1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_more_activity_list = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_more_event_comment_likes = async (path) => {
  const requestObj = {
    path: path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_feeds_list = async (loadMore) => {
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_series_video_detail = async (video_id) => {
  const requestObj = {
    path: `api/series_videos/${video_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_event_video_detail = async (video_id) => {
  const requestObj = {
    path: `api/dynamite_event_category_video/by_slug/${video_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_feeds_detail = async (feed_id) => {
  const requestObj = {
    path: `api/feeds/detail_feed_v2/${feed_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const favourite_videos_list = async () => {
  const requestObj = {
    path: `api/series_videos/list_favorite`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const set_favourite_video = async (data) => {
  const requestObj = {
    path: `api/series_videos/favorite_unfavorite`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_new_feed = async (data) => {
  const requestObj = {
    path: `api/feeds/member/v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_new_feed_v1 = async (data) => {
  const requestObj = {
    path: `api/feeds/add_by_client/v2`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_feed_v1 = async (data, feed_id) => {
  const requestObj = {
    path: `api/feeds/update_by_client/v1/${feed_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_feed = async (data, feed_id) => {
  const requestObj = {
    path: `api/feeds/${feed_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const community_feed_action = async (data) => {
  const requestObj = {
    path: `api/feeds/action`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const search_mention_users_api = async (data) => {
  const requestObj = {
    path: `api/feeds/member/list/mention`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_feed_action = async (id) => {
  const requestObj = {
    path: `api/feeds/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pin_feed_action = async (data) => {
  const requestObj = {
    path: `api/feeds/status_action/client`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const report_post_api = async (data) => {
  const requestObj = {
    path: `api/feeds/report_post`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_token_for_a_video_stream = async (id) => {
  const requestObj = {
    path: `api/feeds/member/join/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const leader_board_data_api = async (comment_id) => {
  const requestObj = {
    path: `api/home/leader_board_stats`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
