import React, { useState, useEffect, useRef, useCallback } from "react";
import { useFormik } from "formik";
import { CircularProgress } from "@mui/material";
import { ReactVideoPlayer } from "src/components";
import Chip from "@mui/material/Chip";
import { s3baseUrl } from "src/config/config";
import {
  filteredRecordingList,
  submitVaultFilter,
  vaultCategorySidebarListing,
} from "src/DAL/Programmes/Programmes";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import VaultFilterSidebar from "src/components/Vault/VaultFilterSidebar";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Vault(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [recordingsList, setRecordingsList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingInner, setIsLoadingInner] = useState(true);
  const [filterData, setFilterdata] = useState({
    vault_category: [],
    program: [],
    to_date: null,
    from_date: null,
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [query, setQuery] = useState("");
  const observer = useRef();
  const [loadMore, setLoadMore] = useState(
    "api/program_recording/get_filtered_recording_list?page=0&limit=10"
  );

  const getVaultCategorySidebarListing = async () => {
    const result = await vaultCategorySidebarListing();
    if (result.code === 200) {
      setProgramOptions(result.program);
      setCategoryOptions(result.vault_category);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const getFilteredRecordingList = async (dataObject, type) => {
    let path = loadMore;
    if (type === "filter") {
      path =
        "api/program_recording/get_filtered_recording_list?page=0&limit=10";
    }
    console.log(dataObject, "dataObjectdataObject");
    let postData = {
      ...dataObject,
    };
    if (!dataObject.program) {
      postData.program = [];
    }
    if (!dataObject.vault_category) {
      postData.vault_category = [];
    }
    if (!dataObject.search_text) {
      postData.search_text = query;
    }

    const result = await filteredRecordingList(path, postData);
    if (result.code === 200) {
      if (type) {
        setRecordingsList(result.recording);
      } else {
        var newArray = recordingsList.concat(result.recording);
        setRecordingsList(newArray);
      }

      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoading(false);
      setIsLoadingInner(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingInner(false);
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  const handleResetFilter = () => {
    setIsLoadingInner(true);
    setPageNumber(0);
    setFilterdata({
      vault_category: [],
      program: [],
      to_date: null,
      from_date: null,
    });
    let Object = {
      vault_category: [],
      program: [],
      to_date: null,
      from_date: null,
    };
    getFilteredRecordingList(Object, "filter");
    handleCloseFilter();
  };
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const data = {
        ...filterData,
        search_text: query,
      };
      setIsLoadingInner(true);
      setPageNumber(0);
      getFilteredRecordingList(data, "filter");
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [query]);

  useEffect(() => {
    getVaultCategorySidebarListing();
  }, []);

  useEffect(() => {
    getFilteredRecordingList(filterData);
  }, [loadMore]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mb-3">
        <div className="mb-4">
          <PageDescription parameter="vault_description" else_title="Vault" />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-5 d-flex ">
          <FormControl
            sx={{ m: 1, width: "25ch" }}
            variant="outlined"
            size="small"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={query}
              onChange={handleChangeSearch}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl>
        </div>
        <div className="col-8 svg-color d-flex align-items-center justify-content-end">
          <VaultFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            setFilterdata={setFilterdata}
            filterData={filterData}
            setIsLoading={setIsLoading}
            programOptions={programOptions}
            categoryOptions={categoryOptions}
            setProgramOptions={setProgramOptions}
            setCategoryOptions={setCategoryOptions}
            onSubmitFilter={getFilteredRecordingList}
          />
        </div>
      </div>
      {isLoadingInner ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <>
          {recordingsList.length > 0 ? (
            recordingsList.map((value, index) => {
              return (
                <div
                  className="card recordings-card h-100 secondary-background"
                  key={index}
                >
                  <div className="row section-space">
                    <div className="col-10">
                      <h3 className="programmes-heading">
                        {htmlDecode(value?.title)}
                      </h3>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                      <p className="vault-date text-secondary">
                        {value?.recording_date}
                      </p>
                    </div>
                  </div>
                  <div className="row media-margin">
                    <div className="col-12">
                      <ReactVideoPlayer url={value?.video_url} />
                    </div>

                    {value.audio_recording && (
                      <div className="col-12 mt-3 text-center">
                        <div>
                          <audio
                            controlsList="nodownload"
                            className="w-100"
                            src={s3baseUrl + value?.audio_recording}
                            controls
                          />
                        </div>
                      </div>
                    )}
                    {value?.short_description && (
                      <div className="col-12 section-space">
                        <p style={{ marginBottom: "5px" }}>
                          {htmlDecode(value?.short_description)}
                        </p>
                      </div>
                    )}
                    <div className="col-12 section-space">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: value?.detailed_description,
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-7 program-btn mt-2">
                      <button className="small-contained-button ">
                        {htmlDecode(value?.program?.title)}
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-5 program-category-btn mt-2">
                      {value.vault_category && (
                        <Chip
                          label={htmlDecode(value.vault_category?.vault_title)}
                          color="primary"
                          className="me-1"
                          variant="outlined"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <RecordNotFound title="Recordings Not Found" />
          )}
          {totlePages > pageNumber ? (
            <div className="col-12 text-center">
              <button
                className="small-contained-button mt-3"
                ref={lastBookElementRef}
                disabled={isLoadingMore}
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}

export default Vault;
