import React, { useState, useEffect, useMemo } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { get_site_setting } from "src/DAL/Payment/Payment";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import { add_free_member_by_web } from "src/DAL/WebPages/web-pages";
import { start_mission_api_for_demo } from "src/DAL/MissionLevels/MissionLevels";
import { useNavigate, useParams } from "react-router-dom";

const QuestCardInformation = ({
  handleCardAction,
  isLoading,
  setIsLoading,
  headingTitle,
  classes,
  buttonText,
}) => {
  const stripe = useStripe();
  const { plan_id } = useParams();
  const elements = useElements();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (elements == null) {
  //     return;
  //   }

  //   setIsLoading(true);

  //   const cardElement = elements.getElement(CardElement);
  //   console.log(cardElement, "cardElement");

  //   stripe.createToken(cardElement).then(function (result) {
  //     if (result.error) {
  //       setIsLoading(false);
  //       enqueueSnackbar(result.error.message, { variant: "error" });
  //       return;
  //     }
  //     console.log(result, "result token stripe");
  //     handleCardAction(result.token.id, cardElement);
  //   });
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    setIsLoading(true);

    const result = await start_mission_api_for_demo(plan_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/mission-levels/quest/${plan_id}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <h2 className={`${classes ? classes : "mt-5 mb-3"}`}>{`${
        headingTitle ? headingTitle : "Enter Card Details"
      } `}</h2>
      <div className="row">
        <div className="col-12 text-end">
          <form onSubmit={handleSubmit}>
            <div className="card p-3">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      iconColor: get_root_value("--portal-theme-primary"),
                      color: get_root_value("--text-primary-color"),
                      "::placeholder": {
                        color: "#d9d0d0",
                      },
                      fontSize: "18px",
                    },
                  },
                }}
              />
            </div>
            {isLoading ? (
              <button
                className="small-contained-button mt-4 mb-4"
                disabled={true}
              >
                Processing...
              </button>
            ) : (
              <button
                className="small-contained-button mt-4 mb-4"
                type="submit"
                disabled={!stripe || !elements}
              >
                {buttonText ? buttonText : "Pay Now"}
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default QuestCardInformation;
