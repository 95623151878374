import React from "react";

export default function MissionLevelsOverviewTab(props) {
  const { handleClickStart, missionDetail } = props;

  return (
    <>
      {missionDetail.detailed_description && (
        <div className="row mt-3">
          <div
            dangerouslySetInnerHTML={{
              __html: missionDetail.detailed_description,
            }}
            className="col-12 mission-levels-overview-tab pt-2 fix-mission-paragraph-text"
          ></div>
        </div>
      )}
    </>
  );
}
