import { CardElement, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState, useMemo, useContext } from "react";
import { supportedCountries } from "../../utils/constants";
import { _change_currency } from "../../DAL/web-pages/web-pages";
import { PaymentContext } from "../../Hooks/PaymentContext/PaymentContext";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import { applePay, creditCard, firePay, googlePay, paypal } from "src/assets";
import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function StripeCheckOut({
  stripeOptions = {},
  stripeInputProps = {},
  containerProps = {},
  inputProps = {},
  labelProps = {},
  btnProps = {},
  btnContainer = {},
  getPirces,
  page_result,
  selectedValue,
  plan,
  is_ticket_disable,
  btnTitle,
  btnVar,
}) {
  const { paymentPlan, isLoadingCard, inputState, setInputsState, upsellPlan } =
    useContext(PaymentContext);

  const { bankCurrencies, userInfo } = useContentSetting();

  const stripe = useStripe();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [stripeResult, setStripeResult] = useState(null);

  const { currency, country, show_fire, payment_method } = inputState;
  const allowed_methods = paymentPlan.allowed_payment_methods ?? [];

  const sale_page_detail = null;

  const { finalTotal } = getPirces;

  const is_payment_allowed = (pay_by) => {
    return allowed_methods?.find((method) => method === pay_by);
  };

  // Handle payment method selection
  const handlePaymentMethod = (method) => {
    const isFireMethod = method === "fire";
    setInputsState((prev) => ({
      ...prev,
      currency: isFireMethod ? currency : paymentPlan.plan_currency,
      plan_amount: finalTotal,
      payment_method: method,
    }));
    if (isFireMethod) handleCurrencyChange();
  };

  // Handle currency conversion
  const handleCurrencyConversion = async (toCurrency) => {
    const updateState = (newState) =>
      setInputsState((prev) => ({ ...prev, ...newState }));

    if (finalTotal === 0) {
      updateState({ currency: toCurrency, plan_amount: 0 });
      return;
    }
    updateState({ calculating_currency: true });
    const result = await _change_currency({
      from: paymentPlan.plan_currency || "gbp",
      to: toCurrency.toLowerCase(),
      amount: finalTotal,
    });

    if (result.code === 200) {
      updateState({
        currency: toCurrency.toLowerCase(),
        plan_amount: result.data,
        payment_method: "fire",
        show_fire: true,
        calculating_currency: false,
      });
    } else {
      updateState({ calculating_currency: false });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleCurrencyChange = () => {
    const bankCurrency = bankCurrencies.find(
      (c) => c.value === userInfo?.country
    );
    const toCurrency = bankCurrency?.which_currency_using?.toLowerCase();

    if (!bankCurrency) {
      setInputsState((prev) => ({
        ...prev,
        currency: paymentPlan.plan_currency || "gbp",
        plan_amount: finalTotal,
        payment_method: "credit_card",
        show_fire: false,
      }));
      return;
    }

    setInputsState((prev) => ({
      ...prev,
      currency: toCurrency,
      plan_amount: finalTotal,
      payment_method: "fire",
      show_fire: true,
    }));
  };

  const handleCurrencySelection = (e) => {
    const { value } = e.target;
    setInputsState((prev) => ({ ...prev, currency: value }));

    if (value === paymentPlan.plan_currency) {
      setInputsState((prev) => ({
        ...prev,
        plan_amount: finalTotal,
      }));
    } else {
      handleCurrencyConversion(value);
    }
  };

  // Initialize payment request with Stripe
  useEffect(() => {
    if (!stripe) return;
    const countryCode = supportedCountries.includes(country) ? country : "GB";
    const paymentRequest = stripe.paymentRequest({
      country: countryCode,
      currency: currency || "gbp",
      total: { label: "Total", amount: Math.round(finalTotal * 100) },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setStripeResult(result);
        setInputsState((prev) => ({
          ...prev,
          payment_request: paymentRequest,
        }));
      }
    });
  }, [stripe, finalTotal, currency, country]);

  // Determine available payment methods
  const paymentMethods = useMemo(() => {
    console.log(stripeResult, "stripeResultstripeResultstripeResult");

    const methods = [];
    methods.push({ value: "credit_card", icon: creditCard });

    if (show_fire && is_payment_allowed("fire")) {
      methods.push({ value: "fire", icon: firePay });
    }
    if (stripeResult?.googlePay) {
      methods.push({ value: "google_pay", icon: googlePay });
    }
    if (stripeResult?.applePay) {
      methods.push({ value: "apple_pay", icon: applePay });
    }
    if (is_payment_allowed("paypal")) {
      methods.push({ value: "paypal", icon: paypal });
    }
    return methods;
  }, [stripeResult, show_fire, is_payment_allowed]);

  // Handle initial currency setup
  useEffect(() => {
    if (!country || !currency || !paymentPlan) return;

    const isRecurringPayment = paymentPlan.payment_access === "recursion";
    const is_plan_free = paymentPlan.is_plan_free;

    if (isRecurringPayment || is_plan_free || !is_payment_allowed("fire")) {
      setInputsState((prev) => ({
        ...prev,
        currency: paymentPlan.plan_currency || "gbp",
        plan_amount: finalTotal,
        payment_method: "credit_card",
        show_fire: false,
      }));
      return;
    }

    handleCurrencyChange();
  }, [country, paymentPlan, upsellPlan]);

  // Handle initial currency setup
  useEffect(() => {
    const plan_currency = paymentPlan.plan_currency || "gbp";
    if (inputState.currency && plan_currency !== inputState.currency) {
      handleCurrencyConversion(inputState.currency);
    }
  }, [inputState.currency, finalTotal]);

  return (
    <>
      {(finalTotal > 0 || paymentPlan.payment_access === "recursion") && (
        <>
          {paymentMethods.length > 1 && (
            <>
              <div className="my-2 payment-page-text-color">
                {sale_page_detail?.payment_method_description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sale_page_detail?.payment_method_description,
                    }}
                  ></div>
                ) : (
                  <h3 className="mt-3 mb-3"> Choose Your Payment Method</h3>
                )}
              </div>
              <div className="d-flex flex-wrap">
                {paymentMethods.map((method) => (
                  <div key={method.value} style={{ width: `100px` }}>
                    <div
                      className={`d-flex align-items-center justify-content-center mb-3 me-3 method-pay-button ${
                        inputState.payment_method === method.value
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handlePaymentMethod(method.value)}
                    >
                      <img src={`${method.icon}`} />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {payment_method === "fire" && (
            <div
              className="col-12 form-group position-relative change-currency mt-1 pb-0"
              {...containerProps}
            >
              <FormControl variant="outlined" className="mt-3">
                <InputLabel id="demo-simple-select-outlined-label">
                  Choose Currency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currency}
                  onChange={(e) => handleCurrencySelection(e)}
                  label="Choose Currency"
                  name="currency"
                  className="inputs-fields svg-color"
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                    },
                  }}
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  <MenuItem value={"eur"}>Euro</MenuItem>
                  <MenuItem value={"gbp"}>UK Pounds</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </>
      )}
      {payment_method == "credit_card" && (
        <h3 className="mt-3 mb-0">Enter Card Details</h3>
      )}

      {(finalTotal > 0 || paymentPlan.payment_access === "recursion") &&
        payment_method === "credit_card" && (
          <div className="col-12 mt-2">
            <div
              className="p-3"
              style={{ border: "1px solid #3a3a3a", borderRadius: "5px" }}
              {...stripeInputProps}
            >
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      iconColor: get_root_value("--portal-theme-primary"),
                      color: get_root_value("--text-primary-color"),
                      "::placeholder": {
                        color: "#d9d0d0",
                      },
                      fontSize: "18px",
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      <div className="text-end " {...btnContainer}>
        <button
          className="small-contained-button mt-3 mb-4"
          type="submit"
          disabled={
            isLoadingCard ||
            (plan && plan?.plan_options.length > 0 && !selectedValue)
          }
        >
          {isLoadingCard ? "Please wait..." : "Pay Now"}
        </button>
      </div>
    </>
  );
}
