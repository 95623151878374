import { Avatar, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { fShortenNumber } from "src/utils/formatNumber";
import { goal_statement_yes_icons_new1, infoImages } from "src/assets";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const SourceJourney = ({
  user_data,
  dashBoardData,
  setShowInfoPopup,
  currentBadgeLevel,
}) => {
  const navigate = useNavigate();
  const [loadingButton, setIsLoadingButton] = useState(false);
  const { navbarList } = useContentSetting();

  const handleNavigate = (path) => {
    if (path === "dd") {
      navigate("/dynamite-diary");
    } else {
      navigate("/mediation");
    }
  };
  const handleInfoPopup = () => {
    setShowInfoPopup(
      dashBoardData?.mission_control_setting?.source_journey_info
    );
  };

  let is_daily_dynamite = false;
  let is_mediation = false;
  let find_growth = navbarList.find((nav) => nav.value === "growth_tools");
  if (find_growth) {
    is_daily_dynamite = Boolean(
      find_growth.child_options.find((d) => d.value === "dynamite_diary")
    );
    is_mediation = Boolean(
      find_growth.child_options.find((d) => d.value === "mediation")
    );
  }

  return (
    <div className="source-journey">
      <div className="header bg-source-journey mb-2 position-relative">
        <h4 className="title-source-journey">SOURCE JOURNEY</h4>
        <img
          src={infoImages}
          alt=""
          onClick={handleInfoPopup}
          style={{
            width: "16px",
            position: "absolute",
            right: "10px",
            top: "11px",
            filter: "invert(1)",
            cursor: "pointer",
          }}
        />

        <div className="d-flex justify-content-between mt-2">
          <div
            className="bg-info-of-source-journey text-center cursor-pointer"
            onClick={() =>
              setShowInfoPopup(
                dashBoardData?.mission_control_setting?.dashboard_level_info
              )
            }
          >
            <div className="text-captilize">Level</div>
            <div
              className="info-of-source-journey-text text-captilize"
              style={{ textTransform: "capitalize" }}
            >
              {dashBoardData?.current_badge_level?.title}
            </div>
          </div>
          <div
            className="bg-info-of-source-journey text-center cursor-pointer"
            onClick={() =>
              setShowInfoPopup(
                dashBoardData?.mission_control_setting?.dashboard_coins_info
              )
            }
          >
            <div>Coins</div>
            <div className="info-of-source-journey-text">
              {fShortenNumber(user_data.coins_count)}
            </div>
          </div>
          <div
            className="bg-info-of-source-journey text-center cursor-pointer"
            onClick={() =>
              setShowInfoPopup(
                dashBoardData?.mission_control_setting?.dashboard_mission_info
              )
            }
          >
            <div>Missions</div>
            {user_data?.user_completed_mission_count !== 0 ||
            user_data?.total_mission_count !== 0 ? (
              <div className="info-of-source-journey-text">
                {user_data?.user_completed_mission_count}/
                {user_data?.total_mission_count}
              </div>
            ) : (
              <div className="info-of-source-journey-text">
                {user_data?.user_completed_mission_count}/
                {user_data?.total_mission_count}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container px-0 d-flex align-items-stretch justify-content-center">
        <div className="row source-journey-width">
          <div className="col-12 d-flex flex-fill">
            <div className="bg-reward-badges p-3 w-100">
              <div className="ms-1">
                <h4 className="title-source-journey mb-2">REWARD & BADGES</h4>
                <img
                  src={infoImages}
                  alt=""
                  onClick={() =>
                    setShowInfoPopup(
                      dashBoardData?.mission_control_setting
                        ?.reward_and_badges_info
                    )
                  }
                  style={{
                    width: "16px",
                    position: "absolute",
                    right: "10px",
                    top: "11px",
                    cursor: "pointer",
                  }}
                />
                <div className="container-fluid px-0">
                  <div className="row g-2 mt-0 mb-1">
                    {dashBoardData.all_badges.map((card, index) => {
                      const findedReward = dashBoardData.earned_badges.find(
                        (reward) => reward._id === card._id
                      );

                      return (
                        <div
                          key={index}
                          className="col-1 ms-2 me-2 d-flex justify-content-center mt-0"
                        >
                          <Tooltip title={card.title}>
                            {findedReward ? (
                              <Avatar
                                src={
                                  s3baseUrl +
                                  card?.after_earned_icon?.thumbnail_1
                                }
                                alt=""
                                className="badge-image"
                              />
                            ) : (
                              <Avatar
                                src={s3baseUrl + card.icon.thumbnail_1}
                                alt=""
                                className="badge-image"
                              />
                            )}
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p className="mb-1" style={{ fontSize: "12px" }}>
                  Badges gained by completing missions.
                </p>
              </div>
            </div>
          </div>

          {(is_daily_dynamite || is_mediation) && (
            <div className="col-12 d-flex flex-fill mt-2">
              <div className="bg-today-actions p-4 w-100">
                <h4 className="title-source-journey mb-1">TODAY ACTIONS</h4>
                {is_daily_dynamite && (
                  <div
                    className="d-flex mt-2 cursor-pointer"
                    onClick={() => handleNavigate("dd")}
                  >
                    {!dashBoardData.is_dynamite_diary_added ? (
                      <Icon
                        icon={"eva:alert-circle-fill"}
                        color="#f6bd4b"
                        width={16}
                        height={16}
                      />
                    ) : (
                      <span>
                        <img
                          src={goal_statement_yes_icons_new1}
                          className=""
                          alt="tick"
                          style={{ width: "16px" }}
                        />
                      </span>
                    )}
                    <span className="ms-2" style={{ fontSize: "12px" }}>
                      Daily Dynamite
                    </span>
                  </div>
                )}
                {is_mediation && (
                  <div
                    className="d-flex mt-1 cursor-pointer"
                    onClick={() => handleNavigate("maditation")}
                  >
                    {!dashBoardData.is_dynamite_mediation_added ? (
                      <Icon
                        icon={"eva:alert-circle-fill"}
                        color="#f6bd4b"
                        width={16}
                        height={16}
                      />
                    ) : (
                      <span>
                        <img
                          src={goal_statement_yes_icons_new1}
                          className=""
                          alt="tick"
                          style={{ width: "16px" }}
                        />
                      </span>
                    )}
                    <span className="ms-2" style={{ fontSize: "12px" }}>
                      Meditation
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {!currentBadgeLevel?.description &&
        currentBadgeLevel?.button_text &&
        currentBadgeLevel?.button_url && (
          <a target="_blank" href={currentBadgeLevel?.button_url}>
            <button
              className="small-contained-button w-100 mt-2"
              style={{ textTransform: "uppercase" }}
              onClick={() =>
                handle_change_page(
                  currentBadgeLevel?.button_url,
                  setIsLoadingButton
                )
              }
            >
              {loadingButton
                ? "Please Wait..."
                : currentBadgeLevel?.button_text}
            </button>
          </a>
        )}
    </div>
  );
};

export default SourceJourney;
