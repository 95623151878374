import { Avatar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { get_short_string } from "src/utils/constants";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SkeletonColor from "src/pages/MissionLevels/Skulton";
import WinCreator from "src/pages/Community/components/WinCreator";
import profilePlaceholder from "../../assets/images/profile_placeholder.png";
import { get_community_content } from "src/DAL/Community/Community";
import WinLoadingMore from "src/pages/Community/components/WinLoadingMore";

export default function CommunityStoriesSlider({
  feedsList = [],
  handleOpenWinBox,
  handleShowDetails,
  communityData = {},
  setWinFeedsList = () => {},
  setCommunityData = () => {},
}) {
  const { state } = useLocation();
  const sliderRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  const getCommunityContent = async () => {
    const result = await get_community_content(communityData.load_more);
    if (result.code === 200) {
      setWinFeedsList?.((old) => [...old, ...result.win_feed]);
      setIsLoading(false);
      setCommunityData((old) => ({ ...old, load_more: result.load_more }));
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const adjustHeight = () => {
      const videoDetailImageBox = document.getElementById(
        "video_detail_image_box"
      );
      const scheduleHeading = document.getElementById("schedule-heading-box");

      if (videoDetailImageBox && screen.width > 767) {
        const height = videoDetailImageBox.offsetHeight;
        const element = ref.current;
        if (height > 0 && element) {
          element.style.height = `${height - scheduleHeading.offsetHeight}px`;
          element.style.overflow = "auto";
        }
      }

      if (state?.schedule_id) {
        const element = document.getElementById(state?.schedule_id);
        if (element) {
          const container = element.closest(".mission-levels-schedule-tab");
          const elementPosition = element.offsetTop;
          const containerPosition = container.offsetTop;
          container.scrollTo({
            top: elementPosition - containerPosition,
            behavior: "smooth",
          });
        }
      }
    };

    const timeoutId = setTimeout(adjustHeight, 300);
    return () => clearTimeout(timeoutId);
  }, [ref, feedsList]);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  function getRandomRGBColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    const color1 = `rgb(${red}, ${green}, ${blue}, 0)`;
    const color2 = `rgb(${red}, ${green}, ${blue}, 0.5)`;
    return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
  }

  useEffect(() => {
    if (sliderRef.current) {
      if (feedsList.length >= 4) {
        setIsScrollable(true);
      }
    }
  }, [feedsList]);

  const getCardBg = ({ feed, user }) => {
    if (feed?.feed_images?.length == 0) return getRandomRGBColor();
    let profile_image = user?.profile_image;
    let feed_image = feed?.feed_images[0]?.thumbnail_2;
    let image_url = `url(${s3baseUrl + (profile_image || feed_image)})`;
    if (!feed_image && !profile_image) {
      image_url = `url(${profilePlaceholder})`;
    }

    let rgbColor = "rgba(0, 0, 0, 0.3)";
    let gradient = `linear-gradient( ${rgbColor},  ${rgbColor} ), ${image_url}`;
    return gradient;
  };

  const handleScrolled = (e) => {
    const { scrollWidth, clientWidth, scrollLeft } = e.target;
    const scrolledFromRight = scrollWidth - clientWidth - scrollLeft;
    if (scrolledFromRight <= 1 && communityData.load_more && !isLoading) {
      setIsLoading(true);
      getCommunityContent();
    }
  };

  return (
    <>
      <div className="mission-levels-schedule-tab d-flex">
        <div
          className={`position-relative d-flex align-items-center justify-content-${
            isScrollable ? "start" : "center"
          } overflow-hidden`}
        >
          {isScrollable && (
            <button
              type="button"
              onClick={scrollLeft}
              className="carousel-control-prev"
            >
              <div className="slider-icon">
                <ChevronLeftIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Previous</span>
            </button>
          )}

          {(feedsList?.length > 0 || handleOpenWinBox) && (
            <div
              className={`scrollable-slider h-100 d-flex ${
                isScrollable ? "overflow-auto" : ""
              } ${!isScrollable ? "justify-content-center" : ""}`}
              ref={sliderRef}
              onScroll={handleScrolled}
            >
              {handleOpenWinBox && (
                <WinCreator
                  getCardBg={getCardBg}
                  handleOpenWinBox={handleOpenWinBox}
                />
              )}
              {feedsList?.length > 0 ? (
                feedsList.map((feed, index) => {
                  return (
                    <div
                      className={`ps-1 mb-3 win-story-card ${
                        index === feedsList.length - 1 ? "" : "pe-1"
                      }`}
                      key={feed._id}
                    >
                      <div className="position-relative schadule-card">
                        <div
                          className={`h-100 schedule-click p-0 mb-2 ${
                            feed._id === state?.schedule_id
                              ? "in-progress-schedule"
                              : ""
                          }`}
                          key={feed.title}
                          onClick={() => handleShowDetails(feed)}
                          style={{
                            background: getCardBg({ feed }),
                          }}
                        >
                          <div className="story-user-box">
                            <Avatar
                              alt={feed?.action_info?.name}
                              src={s3baseUrl + feed?.action_info?.profile_image}
                              style={
                                feed.badge_level_info && {
                                  border: `2px solid ${feed.badge_level_info?.color_code}`,
                                }
                              }
                            />
                          </div>

                          {feed.description && (
                            <div
                              className="story-feed-description"
                              dangerouslySetInnerHTML={{
                                __html: get_short_string(feed.description, 50),
                              }}
                            ></div>
                          )}
                          <div className="story-user-name-box">
                            <h6 className="story-user-name">
                              {feed?.action_info?.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <SkeletonColor />
              )}
              {isLoading && <WinLoadingMore />}
            </div>
          )}

          {isScrollable && (
            <button
              className="carousel-control-next"
              type="button"
              onClick={scrollRight}
            >
              <div className="slider-icon">
                <ChevronRightIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
