import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import Community from "../Community/Community";
import UserProgrammes from "./UserProgrammes";
import UsersLevelMissions from "./UsersLevelMissions";
import UsersCompletedChallenges from "./UsersCompletedChallenges";

const challenges_array = [
  {
    _id: "66b468c62318f0291aa08eae",
    mission_lavel_info: {
      title: "Dynamite level",
      _id: "66b3cf9c06eb40017cc29062",
    },
    title: "I AM - Challenge - What you say after I AM follows you!",
    video_url: "https://vimeo.com/1048341542",
    short_description:
      "In this 30-day challenge, we ask you to sit in this music and feel the abundance of all that you see and experience while the music plays out.",
    detailed_description:
      "<p>21-Day Mindset Challenge. Unlock 8 Success Source Code trainings by completing each day of the challenge and multiplying your productivity and effectiveness by disciplining your mind and creating lasting success habits.</p>\r\n<h3>Main Key Features</h3>\r\n<ul>\r\n<li>Daily Dynamite - Complete Gratitude and Set Intentions of focus for the day</li>\r\n<li>Unlock 8 SOURCE CODE video teachings from Kim Calvert</li>\r\n<li>Access 13 Audio lessons on the 13 Principles of Success</li>\r\n<li>Accountability through a Positive Community</li>\r\n<li>Earn coins and badges to stay on the leaderboard to activate more missions</li>\r\n</ul>\r\n<h3>Key Benefits</h3>\r\n<ul>\r\n<li>Cultivate a growth mindset in less than 10mins a day</li>\r\n<li>Build success habits that stick and become disciplined for your success</li>\r\n<li>Gain clarity on goals and become focused on daily actions that move the needle</li>\r\n<li>Accountability that eliminates procrastination for good</li>\r\n<li>Connect with like-minded people and top the leader pole to gain rewards</li>\r\n<li>Develop a positive mental attitude and start responding to life with ease.</li>\r\n<li>Improve your focus, sleep, health, and relationships</li>\r\n<li>Install a money mindset so you can attract wealth and inspired ideas.</li>\r\n</ul>\r\n<p>Across 21 days you will complete your daily dynamite cultivating an attitude of gratitude and focusing the mind on key intentions each day. Along with teaching audio from Kim you<br />will expand your mindset and become more clear on your goals with motivation to act. Winning streaks will unlock the Source Codes along the way, these are mindset training<br />videos that will teach you how to create a success mindset whilst understanding how to get out of your own way, eliminate fear, and tap into the power of the law of attraction.</p>",
    status: true,
    order: 1,
    mission_duration: 21,
    rewarded_coins: 5000,
    coins_to_unlock: 0,
    image: {
      thumbnail_1: "challenge_image/16d34647-323a-4b3f-be72-adee48606720.jpg",
      thumbnail_2: "challenge_image/16d34647-323a-4b3f-be72-adee48606720.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    updatedAt: "2025-02-28T12:45:07.683Z",
    createdAt: "2024-12-14T19:11:04.837Z",
    __v: 43,

    schedule_unlock_type: "drip_sequence",
    schedule_notification_time: "",
    badge_configration: [
      {
        _id: "65d5bf6694df1718ce4afb08",
        no_of_badges: "1",
      },
    ],
    question_configration: {
      show_question: true,
      button_text: "View Your Answers ",
      submit_button_text: "Submit Answers",
      questions_top_description:
        '<h3 style="text-align: center;">Interactive Learning Experience&nbsp;</h3>\n<p style="text-align: center;">Engage actively with this content by answering thought-provoking questions. Reflect, share insights, and deepen your understanding as you progress through this interactive session.</p>',
      thanks_description:
        '<h2 style="text-align: center;">Thank you for taking the time to submit your answers.&nbsp;</h2>',
    },
    road_map_levels: [],
    badges_to_unlock: [],
    section_border_color: "",
    mission_access_type: "both",
    membership_levels: [
      {
        _id: "6752a44a8c71790071541514",
        title: "Levels 1-3 - Source Paid Membership",
        icon: {
          thumbnail_1:
            "membership_level/9b9f790d-0689-4133-ae12-7adbe81f7ab2.png",
        },
        membership_level_badges: [
          {
            _id: "6752a5178c71790071541844",
            title: "Dynamite",
            icon: {
              thumbnail_1:
                "membership_level/eaffc1c4-e53f-49ff-9bf2-de587de39a2b.png",
            },
          },
          {
            _id: "6752a5318c71790071541991",
            title: "Rocket",
            icon: {
              thumbnail_1:
                "membership_level/b82c2b98-f35a-4b8c-8128-1fd03efbf137.png",
            },
          },
        ],
      },
    ],
    app_branch_url: "https://dynamitelifestyle.test-app.link/M6JU3MQ1LPb",
    membership_level_info: {
      _id: "6752a44a8c71790071541514",
      title: "Dynamite",
      badge_level_info: {
        _id: "6752a5178c71790071541844",
        title: "Dynamite",
      },
    },
    mission_mode: "sandbox",
    promo_video: "https://vimeo.com/1048341542",
    is_default: true,
    type: "Visioneering",
    is_chat_enabled: false,
    is_paid: false,
    replay_days: 0,
    start_time: "",
    payment_configuration: {
      no_of_days: 0,
    },
    payment_plan: {
      plan_title: "Ignite In Full",
    },
    achieveable_rewards_text: "",
    complete_current_schedule: true,
    is_locked: false,
    is_expired: false,
    user_mission_status: "completed",
    quest_payment_status: "paid",
  },
  {
    _id: "678e3d814dd2261061825dbc",
    mission_lavel_info: {
      title: "",
    },
    membership_level_info: {
      _id: "6752a44a8c71790071541514",
      title: "Dynamite",
      badge_level_info: {
        _id: "6752a5178c71790071541844",
        title: "Dynamite",
      },
    },
    title: "Script Challenge Challenge Completion Message",
    video_url: "https://vimeo.com/1045760314?share=copy",
    short_description:
      "Welcome to this challenge. Just accept the challenge below. Each day for 30 days, title the top of your page with the day's date and the day's number. Then, take a picture and upload it to the upload section for that day. Enjoy 😉 😊 💰 😎 😎",
    detailed_description: "",
    status: true,
    order: 17,
    mission_duration: 3,
    rewarded_coins: 700,
    coins_to_unlock: -12,
    schedule_notification_time: "",
    badge_configration: [
      {
        _id: "65d5bf6694df1718ce4afb08",
        no_of_badges: "1",
      },
    ],
    mission_access_type: "both",
    membership_levels: [],
    app_branch_url: "",
    mission_mode: "live",
    promo_video: "https://vimeo.com/1007699599",

    is_default: false,
    image: {
      thumbnail_1: "challenge_image/3b9b9fe3-9c49-45dd-bb73-56a068947fa9.png",
      thumbnail_2: "challenge_image/3b9b9fe3-9c49-45dd-bb73-56a068947fa9.png",
    },
    schedule_unlock_type: "coins_based",
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    road_map_levels: [],
    badges_to_unlock: [],
    updatedAt: "2025-02-07T07:13:54.851Z",
    createdAt: "2025-01-20T12:11:45.704Z",
    __v: 4,
    type: "Script writing",
    is_chat_enabled: false,
    is_paid: false,
    replay_days: 0,
    start_time: "",
    achieveable_rewards_text: "",
    complete_current_schedule: true,
    payment_plan: {
      plan_title: "Ignite In Full",
    },
    is_locked: false,
    is_expired: false,
    user_mission_status: "completed",
    quest_payment_status: "paid",
  },
  {
    _id: "678f70f74a7fb3ef26502679",
    mission_lavel_info: {
      title: "",
    },
    membership_level_info: {
      _id: "6752a44a8c71790071541514",
      title: "Dynamite",
      badge_level_info: {
        _id: "6752a5178c71790071541844",
        title: "Dynamite",
      },
    },
    title: "I Am Prosperity Meditation Challenge",
    video_url: "https://vimeo.com/1045760314?share=copy",
    short_description:
      "Join in on this meditation challenge by taking some time for the next 30 days to listen to this meditation and experience a state of FLOW. Your mind may wander initially;",
    detailed_description: "",
    status: true,
    order: 18,
    mission_duration: 4,
    rewarded_coins: 5000,
    coins_to_unlock: 200,
    schedule_notification_time: "",
    badge_configration: [],
    mission_access_type: "both",
    membership_levels: [],
    app_branch_url: "",
    mission_mode: "live",
    promo_video: "https://vimeo.com/1045760314?share=copy",
    notification_settings: [],
    is_default: false,
    image: {
      thumbnail_1: "member/7db20c9a-5fdc-442f-854c-627589084681.jpg",
      thumbnail_2: "member/7db20c9a-5fdc-442f-854c-627589084681.jpg",
    },
    schedule_unlock_type: "at_once",
    action_by: "admin_user",
    action_id: "65bb46d30833d09471ccb352",
    road_map_levels: [],
    badges_to_unlock: [],
    updatedAt: "2025-02-07T07:11:32.546Z",
    createdAt: "2025-01-21T10:03:35.332Z",
    __v: 0,

    type: "Meditation",
    achieveable_rewards_text: "",
    complete_current_schedule: true,
    is_chat_enabled: false,
    is_paid: false,
    replay_days: 0,
    start_time: "",
    payment_plan: {
      plan_title: "Ignite In Full",
    },
    is_locked: false,
    is_expired: false,
    user_mission_status: "not_started",
    quest_payment_status: "paid",
  },
];

const UserProfileTabs = ({ data }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const handleNavigate = () => {
    navigate(`/mission/addOrUpdate`);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    sessionStorage.setItem("mission_list_badge_level", newValue);
  };
  const TABS_OPTIONS = [
    {
      title: "Completed Missions",
      component: (
        <UsersLevelMissions
          type={"mission"}
          dataArray={data.missions_completed}
        />
      ),
    },
    {
      title: "Completed Challenges",
      component: (
        <UsersCompletedChallenges
          type={"challenges"}
          dataArray={data?.challanges}
        />
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid ps-0 pe-0">
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default UserProfileTabs;
