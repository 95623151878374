import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
//
import Scrollbar from "../Scrollbar";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment/moment";
// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: "featured", label: "Featured" },
  { value: "newest", label: "Newest" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];

// ----------------------------------------------------------------------

VaultFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

export default function VaultFilterSidebar({
  setFilterdata,
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  formik,
  setIsLoading,
  programOptions,
  categoryOptions,
  setProgramOptions,
  setCategoryOptions,
  onSubmitFilter,
  filterData,
}) {
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterdata((values) => ({ ...values, [name]: value }));
  };

  const handleChangeDate = (value, name) => {
    let todayDate = moment(value.$d).format("YYYY-MM-DD");
    setFilterdata((values) => ({ ...values, [name]: todayDate }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    onSubmitFilter(filterData, "filter");
    onCloseFilter();
  };

  return (
    <>
      <Button
        disableRipple
        sx={{ float: "right" }}
        color="inherit"
        endIcon={<Icon icon={roundFilterList} />}
        onClick={onOpenFilter}
        className="vault-filter-button"
      >
        Filters&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: "none", overflow: "hidden" },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}
            >
              <Typography variant="h6" sx={{ ml: 1 }}>
                Filters
              </Typography>
              <IconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3 }}>
                <div className="text-center">
                  {programOptions.length < 1 && categoryOptions.length < 1
                    ? "No Filter Found"
                    : ""}
                </div>
                {programOptions.length < 1 ? (
                  ""
                ) : (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {programOptions.length < 1 ? "" : "Programme"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="program"
                          value={filterData?.program || []}
                          multiple
                          label={programOptions.length < 1 ? "" : "Programme"}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            handleChange({
                              target: {
                                name: "program",
                                value:
                                  typeof value === "string"
                                    ? value.split(",")
                                    : value,
                              },
                            });
                          }}
                          renderValue={(selected) =>
                            programOptions
                              .filter((level) => selected.includes(level?._id))
                              .map((level) => level?.title)
                              .join(", ")
                          }
                        >
                          {programOptions.map((programOption, index) => (
                            <MenuItem key={index} value={programOption._id}>
                              {htmlDecode(programOption.title)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}

                {categoryOptions.length < 1 ? (
                  ""
                ) : (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {categoryOptions.length < 1 ? "" : "Category"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="vault_category"
                          value={filterData?.vault_category || []}
                          multiple
                          label={categoryOptions.length < 1 ? "" : "Category"}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            handleChange({
                              target: {
                                name: "vault_category",
                                value:
                                  typeof value === "string"
                                    ? value.split(",")
                                    : value,
                              },
                            });
                          }}
                          renderValue={(selected) =>
                            categoryOptions
                              .filter((level) => selected.includes(level?._id))
                              .map((level) => level?.vault_title)
                              .join(", ")
                          }
                        >
                          {categoryOptions.map((categoryOption, index) => (
                            <MenuItem key={index} value={categoryOption._id}>
                              {htmlDecode(categoryOption.vault_title)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}
                <div className="col-12 mt-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      mask="__/__/____"
                      inputFormat="DD-MM-YYYY"
                      label="From Date"
                      value={filterData.from_date}
                      fullWidth
                      onChange={(newValue) => {
                        handleChangeDate(newValue, "from_date");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          className="inputs-fields"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-12 mt-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      mask="__/__/____"
                      inputFormat="DD-MM-YYYY"
                      label="To Date"
                      value={filterData.to_date}
                      fullWidth
                      onChange={(newValue) => {
                        handleChangeDate(newValue, "to_date");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          className="inputs-fields"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </Stack>
            </Scrollbar>

            <Box sx={{ pl: 3, pr: 3, pt: 2 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={handleSubmit}
                startIcon={
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: 5 }}
                  >
                    <path
                      d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                      fill={get_root_value("--portal-theme-primary")}
                    />
                  </svg>
                }
              >
                Filter
              </Button>
            </Box>
            <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 1 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  );
}
