import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  confirm_one_time_payment_for_web_v1,
  confirm_subscription_from_page_redirect,
  pay_now_for_subscription_web_v1,
} from "src/DAL/web-pages/web-pages";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ConfirmPaymentPage({ query }) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("paymentId");
  const payerId = searchParams.get("PayerID");
  const token = searchParams.get("token");
  const cancel_url_status = searchParams.get("status");
  const paymentaccess = localStorage.getItem("payment_plan_Access");
  const handleNavigateToThankyou = () => {
    navigate(`/mission-levels/quest-detail/${params.id}`);
  };
  const handleNavigatedetail = () => {
    navigate(`/mission-levels/quest/${params.id}`);
  };

  const handle_auth_redirect_controller = async (bank_payment_data) => {
    if (cancel_url_status == "cancelled") {
      navigate(`/mission-levels/quest-detail/${params.id}`);
      return;
    }

    let result;
    if (bank_payment_data && bank_payment_data?.payment_method == "paypal") {
      let data = {
        mission_id: params.id,
        plan_option_id: "",
        request_by: "web",
        payment_method: "paypal",
        payerId: payerId,
        paymentId: paymentId,
      };
      if (paymentaccess == "onetime") {
        result = await await confirm_one_time_payment_for_web_v1(data);
      } else {
        let data_recurring = {
          mission_id: params.id,
          plan_option_id: "",
          request_by: "web",
          payment_method: "paypal",
          token: token,
        };
        result = await confirm_one_time_payment_for_web_v1(data_recurring);
      }
    } else {
      let data = {
        mission_id: params.id,
        plan_option_id: "",
        request_by: "web",
        payment_method: "fire",
      };
      result = await await confirm_one_time_payment_for_web_v1(data);
    }

    if (result.code === 200) {
      if (bank_payment_data && bank_payment_data?.payment_method == "paypal") {
        handleNavigatedetail();
      } else {
        handleNavigateToThankyou(result);
      }

      enqueueSnackbar(result.message, {
        variant: "success",
      });
      localStorage.removeItem("bank_payment_data");
      localStorage.removeItem("payment_plan_Access");
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
      navigate(`/mission-levels/quest-detail/${params.id}`);
    }
  };

  useEffect(() => {
    const bank_payment_data = JSON.parse(
      localStorage.getItem("bank_payment_data")
    );
    handle_auth_redirect_controller(bank_payment_data);
  }, []);

  return <CircularProgress className={classes.loading} color="primary" />;
}
