import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { profilePlaceholder } from "src/assets";
import { s3baseUrl } from "src/config/config";
import UserProfileTabs from "./UserProfileTabs";
import MemberTopStats from "./MemberTopStats";
import { UserProfileapi } from "src/DAL/Calender/Calender";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function UserProfile4() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const getEventListing = async () => {
    const result = await UserProfileapi(params?.id);
    if (result.code === 200) {
      setData(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getEventListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(data, "datadata");
  return (
    <div className="container-fluid">
      <div className="col-12">
        <div className="d-flex challenge-video-detail-title">
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h2 className="d-inline ms-2" style={{ fontSize: "22px" }}>
              Member Profile
            </h2>
          </span>
        </div>
      </div>
      <div className="main-card row">
        <div className="col-2  text-center p-3 pe-0 pb-1">
          <div className="user-profile-container">
            {data?.user_data.profile_image ? (
              <img
                src={s3baseUrl + data?.user_data.profile_image}
                className="profile-image-user"
                style={{
                  borderRadius: "50%",
                  width: "96px",
                  height: "93px",
                  border: `3px solid ${data?.badge_level?.color_code}`,
                  margin: "auto",
                }}
              />
            ) : (
              <img
                src={profilePlaceholder}
                className="profile-image-user"
                style={{
                  borderRadius: "50%",
                  width: "96px",
                  height: "93px",
                  border: `3px solid ${data?.badge_level?.color_code}`,
                  margin: "auto",
                }}
              />
            )}
          </div>
        </div>
        <div className="col-10 ps-1 pe-0">
          <div className="h-100 pt-4">
            <div className="row mx-0 mt-3 mb-0 user-profile-data">
              <div className="col-5 col-md-6 col-lg-4 mb-2 ps-0">
                <h2 className="mb-0 ps-0" style={{ fontSize: "18px" }}>
                  {data?.user_data?.first_name +
                    " " +
                    data?.user_data?.last_name}
                </h2>
                <p className="text-muted">{data?.user_data?.email}</p>
              </div>
              <div className="col-5 col-md-6 col-lg-6 mb-2">
                <h6 style={{ color: "white" }} className="mb-1">
                  {" "}
                  REWARD &amp; BADGES
                </h6>
                <div
                  className="row g-2 mt-0 mb-1 bg-reward-badges"
                  style={{ background: "transparent" }}
                >
                  {data?.all_badges.map((card, index) => {
                    const findedReward = data?.earned_badges.find(
                      (reward) => reward._id === card._id
                    );

                    return (
                      <div
                        key={index}
                        className="col-1 ms-0 me-1 d-flex justify-content-center mt-0"
                      >
                        <Tooltip title={card.title}>
                          {findedReward ? (
                            <Avatar
                              src={
                                s3baseUrl + card?.after_earned_icon?.thumbnail_1
                              }
                              alt=""
                              className="badge-image"
                            />
                          ) : (
                            <Avatar
                              src={s3baseUrl + card.icon.thumbnail_1}
                              alt=""
                              className="badge-image"
                            />
                          )}
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <MemberTopStats data={data} />
      </div>
      <div className=" row">
        <div className="mt-3 ps-0 pe-0">
          <UserProfileTabs data={data} />
        </div>
      </div>
    </div>
  );
}
