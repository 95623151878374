import { CircularProgress, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { lockIcon } from "src/assets";
import { ReactVideoPlayer } from "src/components";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import MentorChatCard from "./MentorChatCard";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { htmlDecode } from "src/utils/convertHtml";
import { _get_category_videos } from "src/DAL/BonusContent/BonusContent";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MentorShipVideos = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [videosList, setVideosList] = useState([]);
  const [mainVideo, setMainVideo] = useState({});
  const [mentorShip, setMentorShip] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const { category_slug } = useParams();

  const MentorShipList = async () => {
    const result = await _get_category_videos(category_slug);
    if (result.code === 200) {
      setVideosList(result.data);
      setMentorShip(result.mentorship_category);
      setMainVideo(result.first_unlocked_video);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClick = (video) => {
    if (video.is_locked === false) {
      setMainVideo(video);
      scrollTo(0, 0);
    }
  };

  const handlePayout = () => {
    navigate(`/mentorship-payment/${category_slug}/${mentorShip.plan}`);
  };

  console.log(
    mentorShip,
    mainVideo,
    "datadatadatadatadatadatadatadatadatadatadata"
  );

  useEffect(() => {
    MentorShipList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12">
            <div className="d-flex">
              <span>
                <IconButton
                  className="back-screen-button"
                  onClick={() => navigate("/mentorship")}
                >
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <h2 className="ms-2">{htmlDecode(mentorShip.title)}</h2>
            </div>
          </div>
          {mainVideo.title && (
            <>
              <div
                className={`col-12 mt-4 ${showChat ? "col-lg-8" : "mb-3"}`}
                id="video-col"
              >
                <div className="feed-image">
                  <h2 className="mb-3">{mainVideo.title}</h2>
                  <ReactVideoPlayer url={mainVideo?.video_url} />
                </div>
              </div>
              {showChat && (
                <MentorChatCard
                  mainVideo={mainVideo}
                  banner_data={mentorShip}
                  setIsLoadingPage={setIsLoading}
                />
              )}
              <div className="col-12 text-end">
                <button
                  onClick={() => setShowChat(!showChat)}
                  className="ms-3 comment-submit-button"
                >
                  {showChat ? "Hide Chat" : "Show Chat"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {videosList.length > 0 && (
        <div className="container mt-5">
          <div className="row">
            {videosList.map((video) => {
              let classes = "";
              let locked_statement = "Locked";
              if (video._id == mainVideo._id) {
                classes = "highlight_video";
              }
              if (video.locked_statement) {
                locked_statement = video.locked_statement;
              }
              return (
                <div
                  className={`col-12 col-md-6 col-lg-4 pt-4 d-flex justify-content-center ${
                    video.is_locked ? "" : "cursor-pointer"
                  }`}
                  onClick={(e) => {
                    if (video.is_locked && video.upgrade_button_text) {
                      handlePayout();
                    } else {
                      handleClick(video);
                    }
                  }}
                >
                  <div className={`video_section position-relative ${classes}`}>
                    <div className="video_img_dev position-relative">
                      <img
                        src={s3baseUrl + video.image.thumbnail_1}
                        alt=""
                        className="img_width w-100"
                      />
                      {video.is_locked && (
                        <div className="lock_btn lock_icon_button">
                          <button style={{ display: "flex" }}>
                            <img src={lockIcon} alt="" width={20} />
                            &nbsp;{locked_statement}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="p-3">
                      <h3>{video.title}</h3>
                      <p>{video.short_description}</p>
                      {video.is_locked && video.upgrade_button_text && (
                        <div className="d-flex justify-content-center btn_pos_top">
                          <div className="btn_pos">
                            <div className="pt-4 pb-2 text-center upgrade_button">
                              <button
                                className="upgrade-button"
                                onClick={handlePayout}
                              >
                                {video.upgrade_button_text}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="container thanks-page-card mt-5">
        <div
          className="row"
          style={{ color: "white", justifyContent: "center" }}
        >
          <div
            className="col-lg-6 col-md-6 col-sm-6 p-3 for-mobile"
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: mentorShip.banner_1_description,
              }}
            ></div>
            {mentorShip.upgrade_button_1_text && (
              <div className="button-center mt-4">
                <button className="upgrade-button text-center">
                  <a href={mentorShip.upgrade_button_1_link} target="_blank">
                    {mentorShip.upgrade_button_1_text}
                  </a>
                </button>
              </div>
            )}
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-6 p-3 for-mobile"
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: mentorShip.banner_2_description,
              }}
            ></div>
            {mentorShip.upgrade_button_2_text && (
              <div className="button-center mt-4">
                <button className="upgrade-button text-center">
                  <a href={mentorShip.upgrade_button_2_link} target="_blank">
                    {mentorShip.upgrade_button_2_text}
                  </a>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorShipVideos;
