import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const AddEventApi = async (data) => {
  const requestObj = {
    path: `api/event/add_member_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_new_event_api = async (data) => {
  const requestObj = {
    path: `api/event/add/by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_member_event_new_api = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/update/by_admin/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_event_api = async (data) => {
  const requestObj = {
    path: `api/event/delete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const connect_with_google = async (data) => {
  const requestObj = {
    path: `app/add_user_calendar_event`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const eventsListing = async (data) => {
  const requestObj = {
    path: `api/member/member_event_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DashboardAppNew = async (data) => {
  const requestObj = {
    path: `api/member/client_dashboard/v4?&badge_id=${data ?? ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const UserProfileapi = async (id) => {
  const requestObj = {
    path: `api/member/profile/get/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const new_events_listing_api = async (data) => {
  const requestObj = {
    path: `api/member/member_event_list/v1`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const new_event_list_api = async (data) => {
  const requestObj = {
    path: `api/event/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteEvent = async (event_slug) => {
  const requestObj = {
    path: `api/event/${event_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditEventApi = async (event_slug, data) => {
  const requestObj = {
    path: `api/event/update_member_event/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_member_event_api = async (event_slug, data) => {
  const requestObj = {
    path: `api/event/update/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_event_iteration_api = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/event_iteration/update/by_member/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
