import { Icon } from "@iconify/react";
import React from "react";
import { MissionCoin } from "src/assets";
import { fShortenNumber } from "src/utils/formatNumber";

export default function MemberTopStats(props) {
  const { data } = props;
  return (
    <>
      <div className="col-12 col-md-3 mt-2 mb-3 pe-0">
        <div className="profile-card">
          <div className="d-flex align-items-center">
            <div className="profile-stat-icon">
              {/* <Icon icon="stash:trophy-light" /> */}
              <p className="mb-0" style={{ fontSize: "21px" }}>
                🔥
              </p>
            </div>
            <div>
              <h4 className="mb-1">{data?.user_streak}</h4>
              <h6 className="text-muted">DD Streak</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 mt-2 mb-3 pe-0">
        <div className="profile-card">
          <div className="d-flex align-items-center">
            <div className="profile-stat-icon">
              {/* <Icon icon="vaadin:coin-piles" /> */}
              <img src={MissionCoin} style={{ width: "20px" }} />
            </div>
            <div>
              <h4 className="mb-1">
                {fShortenNumber(data?.user_data?.coins_count)}
              </h4>
              <h6 className="text-muted">Total Coins</h6>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="col-12 col-md-3 mt-2 mb-3 pe-0">
        <div className="profile-card">
          <div className="d-flex align-items-center">
            <div className="profile-stat-icon">
              {/* <Icon icon="hugeicons:target-02" /> */}
              <img
                src={
                  "https://dd-feature-app-bucket.s3.amazonaws.com/upload/7452582d-05f1-4b2c-bb0d-612d25396b7d.png"
                }
                style={{ width: "20px" }}
              />
            </div>
            <div>
              <h4 className="mb-1">{data?.mission_completed_count}</h4>
              <h6 className="text-muted">Completed missions</h6>
            </div>
          </div>
        </div>
      </div>{" "}
      {/* <div className="col-12 col-md-3 mt-2 mb-3 pe-0">
        <div className="profile-card">
          <div className="d-flex align-items-center">
            <div className="profile-stat-icon">
              <Icon icon="clarity:event-line" />
            </div>
            <div>
              <h4 className="mb-1">2</h4>
              <h6 className="text-muted">Completed Quest</h6>
            </div>
          </div>
        </div>
      </div> */}
      <div className="col-12 col-md-3 mt-2 mb-3">
        <div className="profile-card">
          <div className="d-flex align-items-center">
            <div className="profile-stat-icon">
              {/* <Icon icon="stash:trophy-light" /> */}
              <img
                src={
                  "https://dd-feature-app-bucket.s3.amazonaws.com/upload/81a7606e-5ad7-4ea9-8c53-3ad455051a55.png"
                }
                style={{ width: "20px" }}
              />
            </div>
            <div>
              <h4 className="mb-1">{data?.completed_challanges}</h4>
              <h6 className="text-muted">Challenge</h6>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
