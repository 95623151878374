import { Avatar } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  mission_level_detail_New_api,
  mission_level_detail_New_api_load_more,
} from "src/DAL/MissionLevels/MissionLevels";
import { NotFoundNew, point } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { fShortenNumber, get_kmb_number } from "src/utils/formatNumber";
import { medal_1, medal_2, medal_3 } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CoinsLeadBoardPopup from "../MissionLevels/CoinsLeadBoardPopup";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import InfoPopup from "../MissionControlNew/components/InfoPopup";
import CloinsLeaderPopup from "./CloinsLeaderPopup";
import { number_with_comma } from "src/utils/constants";

export default function LeaderBoardCoins({
  data,
  title,
  handleClickAll,
  streakLeaderBoard,
  setStreakLeaderBoard,
  loadMorePath,
  setLoadMorePath,
  leftHeight,
  userData,
}) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showAllPopUp, setShowAllPopUp] = useState(false);
  const { userInfo } = useContentSetting();

  const observer = useRef();
  const { mission__id } = useParams();
  const get_mission_detail = async () => {
    let PostData = {
      mission_id: mission__id,
      tab: "dashboard",
      data: "coins_leader_board",
    };
    const result = await mission_level_detail_New_api_load_more(
      loadMorePath,
      PostData
    );
    if (result.code == 200) {
      let newArray = streakLeaderBoard.concat(result.coins_leader_board_stats);

      setStreakLeaderBoard(newArray);

      setLoadMorePath(result.coins_load_more);
      setIsLoadingMore(false);
    } else {
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      get_mission_detail(loadMorePath);
    }
  };

  console.log(userData, "userDatauserData");
  return (
    <div
      className="card p-3 members-leader-board  "
      style={{ height: leftHeight ?? "300px", overflow: "auto" }}
    >
      <div className="d-flex justify-content-between align-items-baseline">
        <span className="challenge-heading unlock-color">{title}</span>
        <span
          className="challenge-heading unlock-color view-all-button "
          onClick={() => setShowAllPopUp(true)}
        >
          {" "}
          View All
        </span>
      </div>

      <hr />
      {streakLeaderBoard.length > 0 ? (
        streakLeaderBoard.map((item, index) => {
          return (
            <div
              className={`d-flex justify-content-between align-items-center mt-2 `}
              key={item._id}
            >
              <div
                className="d-flex align-items-center"
                style={{ width: "70%" }}
              >
                {index == 0 ? (
                  <img src={medal_1} alt="medal_1" className="me-2 medal-img" />
                ) : index == 1 ? (
                  <img
                    src={medal_2}
                    alt="medal_2"
                    className="me-2 medal-img  "
                  />
                ) : index == 2 ? (
                  <img
                    src={medal_3}
                    alt="medal_3"
                    className="me-2  medal-img  "
                  />
                ) : (
                  <span className="me-2 ms-2">{index + 1}. </span>
                )}
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  alt={item.user_info?.name}
                  src={s3baseUrl + item.user_info?.profile_image}
                />
                <div className="user-name ms-2 d-flex align-items-center">
                  {item.user_info?.first_name + " " + item.user_info?.last_name}
                  {item.user_info?._id == userInfo._id && (
                    <img src={point} className={`pointer_hand`} />
                  )}
                </div>
              </div>
              <div className="user-coins" style={{ width: "30%" }}>
                {number_with_comma(item.mission_attracted_coins)} Coins
              </div>
            </div>
          );
        })
      ) : (
        <>
          <RecordNotFound title="Data not found" show_new_image={true} />
        </>
      )}

      {streakLeaderBoard.length - 1 < userData?.leader_board_rank && (
        <>
          <>
            <div
              key={"2232"}
              className="sections d-flex justify-content-between gap-3 p-3 pt-0 pb-0 bg-coins-leaderboard-data"
            >
              <div>
                <div>
                  <span className="me-2">.</span>
                </div>
              </div>
            </div>
            <div
              key={"2232"}
              className="sections d-flex justify-content-between gap-3 p-3 pt-0 pb-0 bg-coins-leaderboard-data"
            >
              <div>
                <div>
                  <span className="me-2">.</span>
                </div>
              </div>
            </div>
            <div
              key={"2232"}
              className="sections d-flex justify-content-between gap-3 p-3 pt-0 pb-0 bg-coins-leaderboard-data"
            >
              <div>
                <div>
                  <span className="me-2">.</span>
                </div>
              </div>
            </div>
          </>
          {userData && (
            <div
              className={`d-flex justify-content-between align-items-center mt-2 `}
            >
              <div
                className="d-flex align-items-center"
                style={{ width: "70%" }}
              >
                <span className="me-2 ms-2">
                  {userData?.leader_board_rank}.{" "}
                </span>

                <Avatar
                  sx={{ width: 30, height: 30 }}
                  alt={userInfo.first_name}
                  src={s3baseUrl + userInfo?.profile_image}
                />
                <div className="user-name ms-2 d-flex align-items-center">
                  {userInfo.first_name + " " + userInfo.last_name}
                  <img src={point} className={`pointer_hand`} alt="point" />
                </div>
              </div>
              <div className="user-coins" style={{ width: "30%" }}>
                {number_with_comma(userData?.mission_attracted_coins)} Coins
              </div>
            </div>
          )}
        </>
      )}

      {handleClickAll && (
        <div className="text-end mt-2">
          <span className="view-all-boards" onClick={handleClickAll}>
            View all
          </span>
        </div>
      )}

      <GeneralModelBox
        open={Boolean(showAllPopUp)}
        setOpen={setShowAllPopUp}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <CloinsLeaderPopup
            handleCross={() => setShowAllPopUp("")}
            showInfoPopup={showAllPopUp}
            streakLeaderBoard={streakLeaderBoard}
            setStreakLeaderBoard={setStreakLeaderBoard}
            loadMorePath={loadMorePath}
            setLoadMorePath={setLoadMorePath}
          />
        }
      />
    </div>
  );
}
