import { Avatar, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  click,
  cross,
  point,
  tick,
  videoShowLock,
  warning_icon,
} from "src/assets";
import { s3baseUrl } from "src/config/config";
import { dd_date_format, get_short_string } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SkeletonColor from "src/pages/MissionLevels/Skulton";

export default function SchadulesListDashBoard({
  missionCommunity,
  currentMissionSchedules,
  type,
}) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mission__id } = useParams();
  const sliderRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const adjustHeight = () => {
      const videoDetailImageBox = document.getElementById(
        "video_detail_image_box"
      );
      const scheduleHeading = document.getElementById("schedule-heading-box");

      if (videoDetailImageBox && screen.width > 767) {
        const height = videoDetailImageBox.offsetHeight;
        const element = ref.current;
        if (height > 0 && element) {
          element.style.height = `${height - scheduleHeading.offsetHeight}px`;
          element.style.overflow = "auto";
        }
      }

      if (state?.schedule_id) {
        const element = document.getElementById(state?.schedule_id);
        if (element) {
          const container = element.closest(".mission-levels-schedule-tab");
          const elementPosition = element.offsetTop;
          const containerPosition = container.offsetTop;
          container.scrollTo({
            top: elementPosition - containerPosition,
            behavior: "smooth",
          });
        }
      }
    };

    const scrollToCurrentSchedule = () => {
      if (sliderRef.current && currentMissionSchedules) {
        const currentElement = document.getElementById(
          currentMissionSchedules._id
        );
        if (currentElement) {
          const sliderWidth = sliderRef.current.clientWidth;
          const elementWidth = currentElement.offsetWidth;
          const elementLeft = currentElement.offsetLeft;
          const scrollPosition =
            elementLeft - sliderWidth / 2 + elementWidth / 2;

          sliderRef.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
        }
      }
    };

    const timeoutId = setTimeout(() => {
      adjustHeight();
      scrollToCurrentSchedule();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [ref, missionCommunity, currentMissionSchedules]);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -145, behavior: "smooth" });
    }
  };

  const HandleNavigate = (item) => {
    if (type == "quest") {
      navigate(`/mission-levels/quest-details/${mission__id}/${item._id}`, {
        state: item,
      });
    } else {
      navigate(`/mission-levels/schedule-details/${mission__id}/${item._id}`, {
        state: item,
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 145, behavior: "smooth" });
    }
  };

  const currentScheduleIndex = missionCommunity.findIndex(
    (item) => item._id === currentMissionSchedules?._id
  );
  useEffect(() => {
    if (sliderRef.current) {
      // const isScrollableNow =
      //   sliderRef.current.scrollWidth > sliderRef.current.clientWidth;
      // setIsScrollable(isScrollableNow);
      // console.log(
      //   sliderRef.current.scrollWidth,
      //   sliderRef.current.clientWidth,
      //   "askjfbsajdbakjsbd"
      // );
      if (missionCommunity.length >= 7) {
        setIsScrollable(true);
      }
    }
  }, [missionCommunity]);

  return (
    <>
      <div
        className="mission-levels-schedule-tab"
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="container position-relative"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden", // Ensure no unwanted scrolling when not needed
          }}
        >
          {isScrollable && (
            <button
              className="carousel-control-prev"
              type="button"
              onClick={scrollLeft}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: "9999",
                display: "contents", // Show button when scrollable
              }}
            >
              <div
                className="slider-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffd889",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                <ChevronLeftIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Previous</span>
            </button>
          )}
          {missionCommunity.length > 0 && (
            <>
              <div
                className={`scrollable-slider h-100 d-flex ${
                  isScrollable ? "overflow-auto" : ""
                } ${!isScrollable ? "justify-content-center" : ""}`}
                ref={sliderRef}
              >
                {missionCommunity && missionCommunity.length > 0 ? (
                  missionCommunity.map((schedule, index) => (
                    <div
                      id={schedule._id}
                      className="pe-1 ps-1 mb-2"
                      style={{
                        width: "125px",
                        flex: "none",
                        cursor: "pointer",
                      }}
                      key={schedule._id}
                      onClick={() => HandleNavigate(schedule)}
                    >
                      <div className="text-center position-relative ">
                        {schedule.schedule_status === "completed" ? (
                          <div className="completeImage">
                            <img src={tick} alt="Completed" />
                          </div>
                        ) : schedule.allowed_status === "missed" ? (
                          <div className="missedImage">
                            <img src={cross} alt="Missed" />
                          </div>
                        ) : schedule.allowed_status === "warning" ? (
                          <div className="warningImage">
                            <img src={warning_icon} alt="Warning" />
                          </div>
                        ) : null}
                        {(schedule.icon?.thumbnail_1 ||
                          schedule.image?.thumbnail_2) && (
                          <>
                            <Tooltip
                              title={
                                type !== "quest"
                                  ? schedule.schedule_status == "completed"
                                    ? "This Schedule has been completed. "
                                    : schedule.allowed_status == "missed"
                                    ? "You have missed this schedule."
                                    : ""
                                  : ""
                              }
                            >
                              <img
                                src={
                                  s3baseUrl +
                                  (schedule.icon?.thumbnail_1 ||
                                    schedule.image?.thumbnail_2)
                                }
                                className={`${
                                  currentMissionSchedules?._id === schedule?._id
                                    ? "currentSchedules"
                                    : ""
                                } schelduleListImage`}
                                style={{
                                  opacity:
                                    currentScheduleIndex >= index ? 1 : 0.5,
                                }}
                                alt={schedule.title}
                              />
                            </Tooltip>
                            {currentMissionSchedules?._id === schedule?._id && (
                              <>
                                {schedule.schedule_status !== "completed" &&
                                  schedule.allowed_status !== "missed" && (
                                    <img
                                      src={point}
                                      alt="click"
                                      className="click"
                                    />
                                  )}
                              </>
                            )}
                          </>
                        )}
                        <Tooltip title={schedule.title}>
                          <p style={{ fontSize: "12px" }} className="mt-2 mb-0">
                            {get_short_string(schedule.title, 24)}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  ))
                ) : (
                  <SkeletonColor />
                )}
              </div>
            </>
          )}
          {isScrollable && (
            <button
              className="carousel-control-next"
              type="button"
              onClick={scrollRight}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: "9999",
                display: "contents", // Show button when scrollable
              }}
            >
              <div
                className="slider-icon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#ffd889",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              >
                <ChevronRightIcon style={{ color: "black" }} />
              </div>
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
