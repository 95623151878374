import * as yup from "yup";

export const validationSchema = yup.object().shape({
  first_name: yup.string().min(3).max(30),
  last_name: yup.string().min(3).max(30).trim(),
  email: yup.string().trim().email({ minDomainAtoms: 2 }),
  street: yup.string().min(3, "Please enter valid address").trim(),
  city: yup.string().min(3).max(25).trim(),
  zip_code: yup.string().min(3).max(25).trim(),
  state: yup.string().min(3).max(25).trim(),
  password: yup.string().min(5).max(25).trim(),
  phone: yup.string().trim().required("Phone number is required"),
  tickets_user: yup.array().of(
    yup.object().shape({
      first_name: yup.string().min(3).max(30),
      last_name: yup.string().min(3).max(30).trim(),
      email: yup.string().trim().email({ minDomainAtoms: 2 }),
    })
  ),
});

export const handleFormValidation = async (e, length) => {
  const { name, value } = e.target;
  if (name == "first_name" && value.length < length) {
    return `First name must be at least ${length} characters`;
  } else if (name == "last_name" && value.length < length) {
    return `Last name must be at least ${length} characters`;
  } else if (name == "email") {
    const emailcheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailcheck.test(value)) {
      return "Please enter valid email address";
    }
  } else if (name == "password" && value.length < length) {
    return `Password must be at least ${length} characters`;
  } else if (name == "street" && value == "") {
    return `Address is required`;
  } else if (name == "city" && value == "") {
    return `City is required`;
  } else if (name == "state" && value == "") {
    return `State is required`;
  } else if (name == "zip_code" && value == "") {
    return `ZipCode is required`;
  } else if (name == "phone" && value.length < length) {
    return "Phone is required";
  } else if (value == "") {
    return "";
  }
  return "";
};
