import React from "react";
import { CircularProgress } from "@mui/material";

export default function WinLoadingMore() {
  return (
    <div className="pe-1 mb-3 win-story-card win-post-loader-box">
      <CircularProgress />
    </div>
  );
}
