import React, { useState } from "react";

import { handle_change_page } from "src/utils/constants";

function UpgardePopUp({
  membershipUpgradePopupSettings,
  handleCLloseUpGradePopup,
}) {
  const [upgradeButton, setUpgradeButton] = useState(false);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: membershipUpgradePopupSettings.description,
        }}
        className="mt-3 mb-2"
      ></div>
      {membershipUpgradePopupSettings &&
        membershipUpgradePopupSettings.show_membership_button &&
        membershipUpgradePopupSettings?.upgrade_button_url &&
        membershipUpgradePopupSettings?.upgrade_button_text && (
          <div className="col-12 mb-3 text-center">
            <button
              className="mc-button-contained"
              onClick={() => {
                handle_change_page(
                  membershipUpgradePopupSettings?.upgrade_button_url,
                  setUpgradeButton
                );
                handleCLloseUpGradePopup();
              }}
            >
              {upgradeButton
                ? "Please Wait..."
                : membershipUpgradePopupSettings?.upgrade_button_text}
            </button>
          </div>
        )}
    </>
  );
}

export default UpgardePopUp;
